import { Column, Row } from '@copilot-dash/components'
import { Checkbox, mergeClasses, InfoLabel } from '@fluentui/react-components'
import { useStyles } from './CopilotDashCheckBox.styles'
import { IFilterOption } from './IFilterOption.types'

interface IProps {
  options: IFilterOption[]
  selectedOptions: string[] | undefined
  onChange: (selectedOptions: string[]) => void
  multiple?: boolean
  isGrouped?: boolean
  className?: string
}

export const CopilotDashCheckBox: React.FC<IProps> = ({
  options,
  selectedOptions = [],
  onChange,
  multiple = false,
  isGrouped = true,
  className,
}) => {
  const styles = useStyles()

  const groupedOptions = []
  for (let i = 0; i < options.length; i += 2) {
    groupedOptions.push(options.slice(i, i + 2))
  }

  const handleCheckboxChange = (option: IFilterOption) => {
    let newSelectedOptions: string[]
    if (multiple) {
      if (selectedOptions.includes(option.key)) {
        newSelectedOptions = selectedOptions.filter((item) => item !== option.key)
      } else {
        newSelectedOptions = [...selectedOptions, option.key]
      }
    } else {
      if (selectedOptions.includes(option.key)) {
        newSelectedOptions = []
      } else {
        newSelectedOptions = [option.key]
      }
    }

    onChange(newSelectedOptions)
  }
  if (isGrouped) {
    return (
      <Column>
        {groupedOptions.map((group, index) => (
          <Row key={index}>
            {group.map((option) => (
              <Checkbox
                key={option.key}
                label={option.text}
                checked={selectedOptions.some((selectedOption) => selectedOption === option.key)}
                onChange={() => handleCheckboxChange(option)}
                className={styles.checkboxLayout}
              />
            ))}
          </Row>
        ))}
      </Column>
    )
  } else {
    return (
      <Column>
        {options.map((option) =>
          option.hidden ? null : (
            <Checkbox
              key={option.key}
              label={
                <InfoLabel
                  weight="semibold"
                  info={option.disabled && 'This filter is in use and cannot be deselected.'}
                >
                  {option.text}
                </InfoLabel>
              }
              checked={selectedOptions.some((selectedOption) => selectedOption === option.key)}
              onChange={() => handleCheckboxChange(option)}
              className={mergeClasses(styles.checkboxLayout, className)}
              size="large"
              disabled={option.disabled}
            />
          ),
        )}
      </Column>
    )
  }
}
