import { ApiV2Ticket, ApiV2TicketSource, ApiV2TicketSourceType } from '@copilot-dash/api'
import { ITicketSource, ITicketSourceItem, ITicketSourceItemQuery, ITicketTurnSource } from '@copilot-dash/domain'
import { compact } from 'lodash'
import { getKustoQueryUrl } from './kusto/getKustoQueryUrl'

export function convertToTicketSource(raw: ApiV2Ticket): ITicketSource {
  const sources = raw.sources ?? []

  return {
    ocv: getRoot('OCV'),
    turns: getTurns(),
    raw: sources,
  }

  function getRoot(type: ApiV2TicketSourceType, level?: ApiV2TicketSource['complianceLevel']): ITicketSourceItem {
    const raw = sources.find((source) => {
      return source.type === type && (!level || source.complianceLevel === level)
    })

    return {
      url: raw?.url,
      query: undefined,
      raw: raw,
    }
  }

  function getTurns(): { [messageId: string]: ITicketTurnSource } {
    const results: { [messageId: string]: ITicketTurnSource } = {}

    const relatedIds = compact(sources.map((source) => source.relatedId))
    for (const relatedId of relatedIds) {
      results[relatedId] = getTurn(relatedId)
    }

    return results
  }

  function getTurn(relatedId: string): ITicketTurnSource {
    return {
      conversation: getTurnSourceItem('Conversation', relatedId),
      conversationGroup1: getTurnSourceItem('Conversation', relatedId, 1),
      conversationGroup2: getTurnSourceItem('Conversation', relatedId, 2),
      conversationGroup3: getTurnSourceItem('Conversation', relatedId, 3),
      conversationGroup4: getTurnSourceItem('Conversation', relatedId, 4),
      userpGroup1: getTurnSourceItem('USERP', relatedId, 1),
      userpGroup2: getTurnSourceItem('USERP', relatedId, 2),
      userpGroup3: getTurnSourceItem('USERP', relatedId, 3),
      userpGroup4: getTurnSourceItem('USERP', relatedId, 4),
      userpDebugResponse: getTurnSourceItem('UserpDebugResponse', relatedId, 4),
      substrateSearchOnlineGroup1: getTurnSourceItem('SubstrateSearchOnline', relatedId, 1),
      substrateSearchOnlineGroup2: getTurnSourceItem('SubstrateSearchOnline', relatedId, 2),
      substrateSearchOnlineGroup3: getTurnSourceItem('SubstrateSearchOnline', relatedId, 3),
      substrateSearchOnlineGroup4: getTurnSourceItem('SubstrateSearchOnline', relatedId, 4),
      substrateSearchOfflineGroup1: getTurnSourceItem('SubstrateSearchOffline', relatedId, 1),
      substrateSearchOfflineGroup2: getTurnSourceItem('SubstrateSearchOffline', relatedId, 2),
      substrateSearchOfflineGroup3: getTurnSourceItem('SubstrateSearchOffline', relatedId, 3),
      substrateSearchOfflineGroup4: getTurnSourceItem('SubstrateSearchOffline', relatedId, 4),
      substrateSearchLog: getTurnSourceItem('SubstrateSearchInfo', relatedId),
      substrateSearchLatencyLog: getTurnSourceItem('BizChat3SLatency', relatedId),
      performanceLog: getTurnSourceItem('BizchatPerformanceEventV4', relatedId),
      llmLog: getTurnSourceItem('LLM', relatedId),
      augLoopLog: getTurnSourceItem('AugLoop', relatedId),
      stateDurationLog: getTurnSourceItem('SydneyTuringBotMonitoredScope', relatedId),
      traceLog: getTurnSourceItem('SydneyTuringBotTraceMDS', relatedId),
      extensibility: getTurnSourceItem('SydneyTuringBotChatMessageEventMDS', relatedId),
    }
  }

  function getTurnSourceItem(
    type: ApiV2TicketSourceType,
    messageId: string,
    level?: ApiV2TicketSource['complianceLevel'],
  ): ITicketSourceItem {
    const raw = sources.find((source) => {
      const matchesType = source.type === type
      const matchesMessageId = source.relatedId === messageId
      const matchesLevel = !level || source.complianceLevel === level

      // TODO: This is a workaround for the USERP search data
      if (type === 'USERP') {
        return (
          matchesMessageId && matchesLevel && source.name?.includes('USERPSearch') && !source.name?.includes('Debug')
        )
      }

      // TODO: This is a workaround for the USERP search data
      if (type === 'UserpDebugResponse') {
        return (
          matchesMessageId &&
          matchesLevel &&
          source.name?.includes('USERPSearch') &&
          source.name?.includes('Debug_Response')
        )
      }

      // TODO: This is a workaround for the USERP search data
      if (type === 'SubstrateSearchOnline') {
        return matchesMessageId && matchesLevel && matchesType && !source.name?.includes('USERPSearch')
      }

      return matchesType && matchesMessageId && matchesLevel
    })

    const query = getTurnSourceQuery(type, messageId)

    return {
      url: raw?.url,
      query,
      raw,
    }
  }

  function getTurnSourceQuery(type: ApiV2TicketSourceType, messageId: string): ITicketSourceItemQuery | undefined {
    try {
      const url = getKustoQueryUrl({ ticket: raw, sourceType: type, messageId })
      if (!url) {
        return undefined
      }

      return {
        title: 'Kusto Query',
        description: 'Kusto Query',
        url: url,
      }
    } catch (error) {
      return undefined
    }
  }
}
