import { PromiseSnapshot } from '@copilot-dash/core'
import { getTicketActivityHistoryV2 } from './actions-activity-history/getTicketActivityHistoryV2'
import { getAttachment } from './actions-attachment/getAttachment'
import { getRootCauseDescriptionAttachment } from './actions-attachment/rootCause-attachment/getRootCauseDescriptionAttachment'
import { uploadRootCauseDescriptionImage } from './actions-attachment/rootCause-attachment/uploadRootCauseDescriptionImage'
import { uploadImage } from './actions-attachment/uploadImage'
import { getFilterTags } from './actions-basic/getFilterTags'
import { getProducts } from './actions-basic/getProducts'
import { getTenant } from './actions-basic-tenant/getTenant'
import { searchM365Tenants } from './actions-basic-tenant/searchM365Tenants'
import { getUserPhoto } from './actions-basic-user/getUserPhoto'
import { getUserProfile } from './actions-basic-user/getUserProfile'
import { cancelClusteringRequest, createClusteringRequest } from './actions-cluster-management/createClusterJob'
import { getClusteringConfigList } from './actions-cluster-management/getClusterConfig'
import { getClusterJobListByProduct, getDataSetListByProduct } from './actions-cluster-management/getClusterJobList'
import { getClusteringResult } from './actions-cluster-management/getClusterResult'
import { publishClusterResult } from './actions-cluster-management/publishClusterResult'
import { createComment } from './actions-comment/createComment'
import { deleteComment } from './actions-comment/deleteComment'
import { getCommentCount } from './actions-comment/getCommentCount'
import { getComments } from './actions-comment/getComments'
import { updateComment } from './actions-comment/updateComment'
import { batchUpdateWorkItemCustomTags } from './actions-custom-tags/batchUpdateWorkItemCustomTags'
import { getAllCustomTags } from './actions-custom-tags/getAllCustomTags'
import { getBatchTicketsCustomTags } from './actions-custom-tags/getBatchTicketsCustomTags'
import { getTicketCustomTags } from './actions-custom-tags/getTicketCustomTags'
import { updateAllCustomTags } from './actions-custom-tags/updateAllCustomTags'
import { updateTicketCustomTag } from './actions-custom-tags/updateTicketCustomTag'
import { appendNewFCConversation } from './actions-feedback-copilot/appendNewFCConversation'
import { getFCConversationByUserId } from './actions-feedback-copilot/getFCConversationByUserId'
import { submitFCRagQuery } from './actions-feedback-copilot/submitFCRagQuery'
import { updateFCConversation } from './actions-feedback-copilot/updateFCConversation'
import { updateUserReadFCRagResult } from './actions-feedback-copilot/updateUserReadFCRagResult'
import { getMcpExperimentFeedback } from './actions-mcp/getMcpExperimentFeedback'
import { getRawV1Ticket } from './actions-raw-ticket/getRawV1Ticket'
import { getRawConversation } from './actions-raw-ticket-chat/getRawConversation'
import { getRawConversationGroup1 } from './actions-raw-ticket-chat/getRawConversationGroup1'
import { getRawConversationGroup2 } from './actions-raw-ticket-chat/getRawConversationGroup2'
import { getRawConversationGroup3 } from './actions-raw-ticket-chat/getRawConversationGroup3'
import { getRawConversationGroup4 } from './actions-raw-ticket-chat/getRawConversationGroup4'
import { getRaw3sOfflineDataGroup1 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup1'
import { getRaw3sOfflineDataGroup2 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup2'
import { getRaw3sOfflineDataGroup3 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup3'
import { getRaw3sOfflineDataGroup4 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup4'
import { getRaw3sOnlineDataGroup1 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup1'
import { getRaw3sOnlineDataGroup2 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup3'
import { getRaw3sOnlineDataGroup4 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup4'
import { getRawUserpGroup1 } from './actions-raw-ticket-userp/getRawUserpGroup1'
import { getRawUserpGroup2 } from './actions-raw-ticket-userp/getRawUserpGroup2'
import { getRawUserpGroup3 } from './actions-raw-ticket-userp/getRawUserpGroup3'
import { getRawUserpGroup4 } from './actions-raw-ticket-userp/getRawUserpGroup4'
import { getRootCauseCustomTags } from './actions-root-cause-tags/getRootCauseCustomTags'
import { patchRootCauseCustomTags } from './actions-root-cause-tags/patchRootCauseCustomTags'
import { getOrFetchTicketByTicketId } from './actions-search/searchTicketByTicketId'
import { searchTickets } from './actions-search/searchTickets'
import { deleteQuery } from './actions-search-query/deleteQuery'
import { getMyQueries } from './actions-search-query/getMyQueries'
import { getQuery } from './actions-search-query/getQuery'
import { getTeamQueries } from './actions-search-query/getTeamQueries'
import { updateQuery } from './actions-search-query/updateQuery'
import { putSevalQuerySet } from './actions-seval/putSevalQuerySet'
import { batchUpdateTicketStatusInfo } from './actions-team/batchUpdateTicketStatusInfo'
import { getAllTeams } from './actions-team/getAllTeams'
import { getAssignedTeamMembers } from './actions-team/getAssignedTeamMembers'
import { getTeamAreasAndRootCauses } from './actions-team/getTeamAreasAndRootCauses'
import { getTeamDRIListByTeamId, setTeamDRIList } from './actions-team/getTeamDRIListByTeamId'
import {
  getOrFetchTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringBatchInfoList,
} from './actions-team/getTeamIssueClusteringBatchInfoList'
import {
  getOrFetchTeamIssueClusteringInfoList,
  getTeamIssueClusteringInfoList,
} from './actions-team/getTeamIssueClusteringInfoList'
import { getTeams, getTeamsMap } from './actions-team/getTeams'
import { updateTicketStatusInfo } from './actions-team/updateTicketStatusInfo'
import { addOrOverrideRootCauseV2 } from './actions-team-root-cause/addOrOverrideRootCauseV2'
import { AISearchRootCauses } from './actions-team-root-cause/AISearchRootCauses'
import { createRootCause } from './actions-team-root-cause/createRootCause'
import { deleteRootCause } from './actions-team-root-cause/deleteRootCause'
import {
  getOrFetchRootCauseInfoByRootCauseIdV2,
  getRootCauseInfoByRootCauseIdV2,
} from './actions-team-root-cause/getRootCauseInfoByRootCauseIdV2'
import {
  appendRootCauseByTeam,
  clearRootCauseListByTeam,
  getOrFetchRootCauseListByTeam,
  getRootCauseListByTeam,
  searchRootCauseByTicketCharacter,
  updateRootCauseByTeam,
} from './actions-team-root-cause/getRootCauseListByTeam'
import { getTeamRootCauses } from './actions-team-root-cause/getTeamRootCauses'
import { getTicketRootCause } from './actions-team-root-cause/getTicketRootCause'
import { updateRootCause } from './actions-team-root-cause/updateRootCause'
import { updateRootCauseRecommendationActionV2 } from './actions-team-root-cause/updateRootCauseRecommendationActionV2'
import { updateTicketRootCause } from './actions-team-root-cause/updateTicketRootCause'
import { getTopIssueExtendInfo } from './actions-team-top-issues/getTopIssueExtendInfo'
import { removeTicketFromTopIssue } from './actions-team-top-issues/removeTicketFromTopIssue'
import { batchUpdateTickets, batchUpdateTicketsByWorkItems } from './actions-ticket/batchUpdateTickets'
import { fetchTicketsByTicketIdAndUpdateStore } from './actions-ticket/fetchTicketsByTicketIdaAndUpdateStore'
import { getTicket } from './actions-ticket/getTicket'
import { getTicketContext } from './actions-ticket/getTicketContext'
import { getTicketEmail } from './actions-ticket/getTicketEmail'
import { getTicketEnvironment } from './actions-ticket/getTicketEnvironment'
import { getTicketIdByWorkItemId } from './actions-ticket/getTicketIdByWorkItemId'
import { getTicketMetadata } from './actions-ticket/getTicketMetadata'
import { getTicketResponse } from './actions-ticket/getTicketResponse'
import { getTickets } from './actions-ticket/getTickets'
import { getTicketSystemTags } from './actions-ticket/getTicketSystemTags'
import { getTicketUserConsent } from './actions-ticket/getTicketUserConsent'
import { getTicketUtterance } from './actions-ticket/getTicketUtterance'
import { getTicketVerbatim } from './actions-ticket/getTicketVerbatim'
import { updateTicket } from './actions-ticket/updateTicket'
import { getTicketSource } from './actions-ticket-blob/getTicketSource'
import { getCallFlow } from './actions-ticket-call-flow/getCallFlow'
import { getTelemetryMetrics } from './actions-ticket-call-flow/getTelemetryMetrics'
import { getTicketLastTurnMessageId } from './actions-ticket-chat/getTicketLastTurnMessageId'
import { getTicketMessageIds } from './actions-ticket-chat/getTicketMessageIds'
import { getTicketTurnConversation } from './actions-ticket-chat/getTicketTurnConversation'
import { getTicketTurnConversationCitations } from './actions-ticket-chat/getTicketTurnConversationCitations'
import { getTicketTurnConversationContext } from './actions-ticket-chat/getTicketTurnConversationContext'
import { getTicketTurnMetadata } from './actions-ticket-chat/getTicketTurnMetadata'
import { get3sLogs } from './actions-ticket-chat-3s/get3sLogs'
import { get3sOfflineGeneralDiagnosticData } from './actions-ticket-chat-3s/get3sOfflineGeneralDiagnosticData'
import { get3sOnlineGeneralDiagnosticData } from './actions-ticket-chat-3s/get3sOnlineGeneralDiagnosticData'
import { getTicketDiagnosticData } from './actions-ticket-diagnostic/getTicketDiagnosticData'
import { get3sGwsLogs } from './actions-ticket-kusto/get3sGwsLogs'
import { get3SLatencyLogs } from './actions-ticket-kusto/get3SLatencyLogs'
import { getAugLoopLogs } from './actions-ticket-kusto/getAugLoopLogs'
import { getExtensibilityLog } from './actions-ticket-kusto/getExtensibilityLog'
import { getLlmLogs } from './actions-ticket-kusto/getLlmLogs'
import { getPerformanceLogs } from './actions-ticket-kusto/getPerformanceLogs'
import { getStateDurationLog } from './actions-ticket-kusto/getStateDurationLog'
import { getTraceLogs } from './actions-ticket-kusto/getTraceLogs'
import { getSymptomReport } from './actions-ticket-symptom-report/getSymptomReport'
import { getTicketHealthData } from './actions-ticket-symptom-report/getTicketHealthData'
import { getTicketSearchKusto } from './actions-ticket-userp/getTicketSearchKusto'
import { getTicketSearchQuery } from './actions-ticket-userp/getTicketSearchQuery'
import { getTicketUserpDebugData } from './actions-ticket-userp/getTicketUserpDebugData'
import { batchUpdateWorkItems } from './actions-workitem/batchUpdateWorkItems'
import { getRootCauseRecommendationByFeedbackId } from './actions-workitem/getRootCauseRecommendationByFeedbackId'
import { getWorkItemByFeedbackId } from './actions-workitem/getWorkItemByFeedbackId'
import { IDashStoreContext } from '../IDashStoreContext'

export const DashStoreActions = {
  // 📎 Attachment
  uploadImage: uploadImage,
  getAttachment: getAttachment,
  uploadRootCauseDescriptionImage: uploadRootCauseDescriptionImage,
  getRootCauseDescriptionAttachment: getRootCauseDescriptionAttachment,

  // 🧱 Basic
  getFilterTags: getFilterTags,
  getProducts: getProducts,

  // 🏢 Basic > Tenant
  getTenant: getTenant,
  searchM365Tenants: searchM365Tenants,

  // 🧑‍💻 Basic > User
  getUserProfile: getUserProfile,
  getUserPhoto: getUserPhoto,

  // 💭 Comments
  getCommentCount: getCommentCount,
  getComments: getComments,
  updateComment: updateComment,
  createComment: createComment,
  deleteComment: deleteComment,

  // 🤖 Feedback Copilot
  getFCConversationByUserId: getFCConversationByUserId,
  appendFCConversation: appendNewFCConversation,
  submitFCRagQuery: submitFCRagQuery,
  updateFCConversation: updateFCConversation,
  updateUserReadFCRagResult: updateUserReadFCRagResult,

  // 📈 MCP
  getMcpExperimentFeedback: getMcpExperimentFeedback,

  // 📦 Raw > Ticket > Chat
  getRawConversation: getRawConversation,
  getRawConversationGroup1: getRawConversationGroup1,
  getRawConversationGroup2: getRawConversationGroup2,
  getRawConversationGroup3: getRawConversationGroup3,
  getRawConversationGroup4: getRawConversationGroup4,

  // 📦 Raw > Ticket > Chat > 3S Online
  getRaw3sOnlineDataGroup1: getRaw3sOnlineDataGroup1,
  getRaw3sOnlineDataGroup2: getRaw3sOnlineDataGroup2,
  getRaw3sOnlineDataGroup3: getRaw3sOnlineDataGroup3,
  getRaw3sOnlineDataGroup4: getRaw3sOnlineDataGroup4,

  // 📦 Raw > Ticket > Chat > 3S Offline
  getRaw3sOfflineDataGroup1: getRaw3sOfflineDataGroup1,
  getRaw3sOfflineDataGroup2: getRaw3sOfflineDataGroup2,
  getRaw3sOfflineDataGroup3: getRaw3sOfflineDataGroup3,
  getRaw3sOfflineDataGroup4: getRaw3sOfflineDataGroup4,

  // 📦 Raw > Ticket > Search > USERP
  getRawUserpGroup1: getRawUserpGroup1,
  getRawUserpGroup2: getRawUserpGroup2,
  getRawUserpGroup3: getRawUserpGroup3,
  getRawUserpGroup4: getRawUserpGroup4,

  // 🔍 Search
  searchTickets: searchTickets,
  getOrFetchTicketByTicketId: getOrFetchTicketByTicketId,

  // 🔍 Search > Queries
  updateQuery: updateQuery,
  getTeamQueries: getTeamQueries,
  getMyQueries: getMyQueries,
  getQuery: getQuery,
  deleteQuery: deleteQuery,

  // 👥 Team
  getTeamAreasAndRootCauses: getTeamAreasAndRootCauses,
  getTeamIssueClusteringBatchInfoList: getTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringInfoList: getTeamIssueClusteringInfoList,
  getAllTeams: getAllTeams,
  getTeams: getTeams,
  getTeamsMap: getTeamsMap,
  getAssignedTeamMembers: getAssignedTeamMembers,
  getTeamDRIListByTeamId: getTeamDRIListByTeamId,
  setTeamDRIList: setTeamDRIList,
  batchUpdateTicketStatusInfo: batchUpdateTicketStatusInfo,
  updateTicketStatusInfo: updateTicketStatusInfo,

  // 🐞 Team > Root Cause
  getTeamRootCauses: getTeamRootCauses,
  getRootCauseListByTeam: getRootCauseListByTeam,
  updateTicketRootCause: updateTicketRootCause,
  getOrFetchRootCauseListByTeam: getOrFetchRootCauseListByTeam,
  searchRootCauseByTicketCharacter: searchRootCauseByTicketCharacter,
  getTicketRootCause: getTicketRootCause,
  updateRootCause: updateRootCause,
  createRootCause: createRootCause,
  getRootCauseInfoByIdV2: getOrFetchRootCauseInfoByRootCauseIdV2,
  fetchRootCauseInfoByIdV2: getRootCauseInfoByRootCauseIdV2,
  appendRootCauseByTeam: appendRootCauseByTeam,
  updateRootCauseByTeam: updateRootCauseByTeam,
  clearRootCauseListByTeam: clearRootCauseListByTeam,
  deleteRootCause: deleteRootCause,
  addOrOverrideRootCauseV2: addOrOverrideRootCauseV2,
  fetchRootCauseRecommendationByFeedbackId: getRootCauseRecommendationByFeedbackId,
  updateRootCauseRecommendationActionV2: updateRootCauseRecommendationActionV2,
  aiSearchRootCauses: AISearchRootCauses,

  // 🐞 Team > Work Item
  getWorkItemByFeedbackId: getWorkItemByFeedbackId,
  batchUpdateWorkItems: batchUpdateWorkItems,

  // 🥇 Team > Top Issues
  getOrFetchTeamIssueClusteringBatchInfoList: getOrFetchTeamIssueClusteringBatchInfoList,
  getOrFetchTeamIssueClusteringInfoList: getOrFetchTeamIssueClusteringInfoList,
  getTopIssueExtendInfo: getTopIssueExtendInfo,
  removeTicketFromTopIssue: removeTicketFromTopIssue,

  // 🐞 Ticket
  getRawV1Ticket: getRawV1Ticket, // TODO: Needs to be removed
  getTicket: getTicket,
  getTickets: getTickets,
  getTicketContext: getTicketContext,
  updateTicket: updateTicket,
  batchUpdateTickets: batchUpdateTickets,
  getTicketUtteranceText: getTicketUtterance,
  getTicketResponse: getTicketResponse,
  getTicketVerbatim: getTicketVerbatim,
  getTicketEmail: getTicketEmail,
  getTicketMetadata: getTicketMetadata,
  getTicketEnvironment: getTicketEnvironment,
  getTicketMessageIds: getTicketMessageIds,
  getTicketIdByWorkItemId: getTicketIdByWorkItemId,
  getTicketUserConsent: getTicketUserConsent,
  fetchTicketsByTicketIdAndUpdateStore: fetchTicketsByTicketIdAndUpdateStore,
  batchUpdateTicketsByWorkItems: batchUpdateTicketsByWorkItems,

  // 🕒 Ticket > Activity History
  getTicketActivityHistoryV2: getTicketActivityHistoryV2,

  // 💾 Ticket > Blob File
  getTicketSource: getTicketSource,

  // 🔀 Ticket > CallFlow
  getCallFlow: getCallFlow,
  getTelemetryMetrics: getTelemetryMetrics,

  // 🗣️ Ticket > Chat
  getTicketLastTurnMessageId: getTicketLastTurnMessageId,
  getTicketTurnConversationCitations: getTicketTurnConversationCitations,
  getTicketTurnMetadata: getTicketTurnMetadata,
  getTicketTurnConversation: getTicketTurnConversation,
  getTicketTurnConversationContext: getTicketTurnConversationContext,

  // 🗣️ Ticket > USERP
  getTicketSearchKusto: getTicketSearchKusto,
  getTicketSearchQuery: getTicketSearchQuery,
  getTicketUserpDebugData: getTicketUserpDebugData,

  // 🗣️ Ticket > Chat > 3S
  get3sLogs: get3sLogs,
  get3sOnlineGeneralDiagnosticData: get3sOnlineGeneralDiagnosticData,
  get3sOfflineGeneralDiagnosticData: get3sOfflineGeneralDiagnosticData,

  // 📜 Ticket > Diagnostic
  getTicketDiagnosticData: getTicketDiagnosticData,

  // 📜 Ticket > Kusto
  get3SLatencyLogs: get3SLatencyLogs,
  get3sGwsLogs: get3sGwsLogs,
  getExtensibilityLog: getExtensibilityLog,
  getStateDurationLog: getStateDurationLog,
  getTraceLogs: getTraceLogs,
  getPerformanceLogs: getPerformanceLogs,
  getLlmLogs: getLlmLogs,
  getAugLoopLogs: getAugLoopLogs,

  // 🩺 Ticket > SymptomReport
  getTicketHealthData: getTicketHealthData,
  getSymptomReport: getSymptomReport,

  // 🏷️ Ticket/RootCause > Tags
  getTicketSystemTags: getTicketSystemTags,
  getAllCustomTags: getAllCustomTags,
  getTicketCustomTags: getTicketCustomTags,
  getBatchTicketsCustomTags: getBatchTicketsCustomTags,
  updateAllCustomTags: updateAllCustomTags,
  updateTicketCustomTag: updateTicketCustomTag,
  patchRootCauseCustomTags: patchRootCauseCustomTags,
  getRootCauseCustomTags: getRootCauseCustomTags,
  batchUpdateWorkItemCustomTags: batchUpdateWorkItemCustomTags,

  getClusteringConfigList: getClusteringConfigList,
  createClusteringRequest: createClusteringRequest,
  getClusteringResult: getClusteringResult,
  cancelClusteringRequest: cancelClusteringRequest,
  getClusterJobListByProduct: getClusterJobListByProduct,
  getDataSetListByProduct: getDataSetListByProduct,
  publishClusterResult: publishClusterResult,

  // 🧑‍⚕️ Seval
  putSevalQuerySet: putSevalQuerySet,
} satisfies {
  readonly [name: string]: (context: IDashStoreContext, ...args: never[]) => PromiseSnapshot<unknown> | Promise<unknown>
}
