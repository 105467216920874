import { ReactNode, useState } from 'react'
import { AnyTable } from '../any-table/AnyTable'
import { AdditionalColumnType } from '../any-table-additionalColumn/AdditionalColumnType'
import { AnyTableToolbar } from '../any-table-toolbar/AnyTableToolbar'
import { TabItemToolbar } from '../tab-item/TabItemToolbar'

interface IProps {
  readonly data: unknown[]
  readonly toolbar?: ReactNode
  readonly additionalColumns?: AdditionalColumnType[]
}

export function TabItemForAnyTable({ data, toolbar, additionalColumns }: IProps): ReactNode {
  const [keyword, setKeyword] = useState<string>('')

  return (
    <>
      <TabItemToolbar>
        <AnyTableToolbar keyword={keyword} onSearch={setKeyword}>
          {toolbar}
        </AnyTableToolbar>
      </TabItemToolbar>
      <AnyTable data={data} keyword={keyword} additionalColumns={additionalColumns} />
    </>
  )
}
