import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  ITicketFilterFormData,
  ITicketFilterFormProps,
  ITicketFilterFormRef,
  WatchFn,
  customFiltersToAdd,
} from './TicketFilterForm.types'
import {
  AgentCard,
  CustomFiltersCard,
  DateCard,
  FeedbackDetailsCard,
  FeedbackHandlingCard,
  FeedbackSourceCard,
  PromptDetailsCard,
  QueryProcessingCard,
  ResponseDetailsCard,
} from '../cards'

export const TicketFilterForm = memo(
  forwardRef<ITicketFilterFormRef, ITicketFilterFormProps>(
    ({ onSubmit, defaultValues, productId, teamId, stateDisabled }, ref) => {
      const { control, getValues, setValue, watch } = useForm<ITicketFilterFormData>({ defaultValues: {} })

      const searchScreenCustomizedFilters = app.settings.searchScreenCustomizedFilterItems.use()
      const teamViewCustomizedFilters = app.settings.teamViewCustomizedFilterItems.use()
      const watchFnRef = useRef<WatchFn | null>(null)

      useEffect(() => {
        const subscription = watch((value, { type }) => {
          type && onSubmit(value as ITicketFilterFormData)
        })
        return () => subscription.unsubscribe()
      }, [watch, onSubmit])

      useEffect(() => {
        defaultValues &&
          Object.entries(defaultValues).forEach(([key, value]) => {
            setValue(key as keyof ITicketFilterFormData, value)
          })
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      // Sync the customized filters in UserSetting based on the default values
      useEffect(() => {
        const newSearchScreenFilters = [...searchScreenCustomizedFilters]
        const newTeamViewFilters = [...teamViewCustomizedFilters]
        customFiltersToAdd.forEach((filter) => {
          if (defaultValues?.[filter.key]) {
            if (!searchScreenCustomizedFilters.includes(filter.name)) {
              newSearchScreenFilters.push(filter.name)
            }
            if (!teamViewCustomizedFilters.includes(filter.name)) {
              newTeamViewFilters.push(filter.name)
            }
          }
        })
        app.settings.searchScreenCustomizedFilterItems.set(newSearchScreenFilters)
        app.settings.teamViewCustomizedFilterItems.set(newTeamViewFilters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      useImperativeHandle(
        ref,
        () => ({
          setValues: (values) => {
            values &&
              Object.entries(values).forEach(([key, value]) => {
                setValue(key as keyof ITicketFilterFormData, value)
              })
          },
          getValues: () => getValues(),
          watch: (fn) => {
            watchFnRef.current = fn
          },
        }),
        [getValues, setValue],
      )

      return (
        <>
          <DateCard control={control} />
          <FeedbackSourceCard control={control} />
          <FeedbackDetailsCard control={control} />
          <PromptDetailsCard control={control} />
          <ResponseDetailsCard control={control} />
          <QueryProcessingCard control={control} />
          <FeedbackHandlingCard control={control} productId={productId} teamId={teamId} stateDisabled={stateDisabled} />
          <AgentCard control={control} />
          <CustomFiltersCard control={control} />
        </>
      )
    },
  ),
)
TicketFilterForm.displayName = 'TicketFilterForm'
