import { UsageIntensityCohortSlicer } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const UsageIntensityCohortSlicerFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = Object.values(UsageIntensityCohortSlicer).map((item) => ({
    key: item,
    text: item,
  }))

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-usageIntensityCohortSlicersFilter'}
      filterType={getTagDisplayName('usageIntensityCohortSlicers')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      infoLabelContent={<>Usage Intensity Cohort is computed offline with a potential delay. SLA: 33 hours.</>}
    />
  )
}
