import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { IRootCauseData, IRootCauseListByTeam, ProductIds, RootCauseId, TeamId } from '@copilot-dash/domain'
import { assign, uniq } from 'lodash'
import { AISearchRootCauses } from './AISearchRootCauses'
import { convertRootCauseListV2 } from './converters/convertRootCauseList'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getListByIds } from '../../utils/getListByIds'
import { SearchRootCauseByTicketCharacter } from '../actions-search/SearchRootCauseByTicketCharacter'
import { ISearchTicketArgs } from '../actions-search/SearchTicketAction.types'

export async function getRootCauseListByTeam(
  context: IDashStoreContext,
  teamId: TeamId,
): Promise<IRootCauseListByTeam> {
  const response = await AISearchRootCauses(context, { teamIds: [teamId], offset: 0, count: 10000 })
  return convertRootCauseListV2(response, context)
}

export async function searchRootCauseByTicketCharacter(context: IDashStoreContext, filter: ISearchTicketArgs) {
  // NOTE: @Ethan - offset and count are not used in service side, we keep it here for future use.
  return new SearchRootCauseByTicketCharacter(context).search({ options: { offset: 0, count: 100, ...filter } })
}

export function getOrFetchRootCauseListByTeam(
  context: IDashStoreContext,
  productId: ProductIds,
  teamId: TeamId,
): PromiseSnapshot<IRootCauseListByTeam> {
  return context.getOrFetch({
    get: (state) => {
      const rootCauseIds = state.team.v2RootCauseIdsByTeam?.[teamId]
      if (!rootCauseIds) {
        return undefined
      }
      if (rootCauseIds.status === 'done') {
        return PromiseSnapshots.map(getListByIds(rootCauseIds.data, context.state.team.v2RootCauseMap), (data) => ({
          rootCauseList: data,
        }))
      } else {
        return PromiseSnapshots.map(rootCauseIds, (data) => ({ rootCauseList: [] }))
      }
    },
    set: (state, snapshot) => {
      state.team.v2RootCauseIdsByTeam ??= {}
      state.team.v2RootCauseIdsByTeam[teamId] = PromiseSnapshots.map(snapshot, (data) =>
        data.rootCauseList.map((rc) => rc.issueId),
      )
      PromiseSnapshots.map(snapshot, (data) => {
        data.rootCauseList.forEach((rc) => {
          state.team.v2RootCauseMap ??= {}
          state.team.v2RootCauseMap[rc.issueId] = PromiseSnapshots.done(rc)
        })
      })
    },
    fetch: () => {
      return getRootCauseListByTeam(context, teamId)
    },
    shouldPromiseCompare: false,
  })
}

export async function appendRootCauseByTeam(
  context: IDashStoreContext,
  productId: ProductIds,
  teamId: TeamId,
  rootCause: IRootCauseData,
): Promise<void> {
  context.use.setState((state) => {
    state.team.v2RootCauseMap[rootCause.issueId] = PromiseSnapshots.done(rootCause)
    if (state.team.v2RootCauseIdsByTeam?.[teamId]) {
      state.team.v2RootCauseIdsByTeam[teamId] = PromiseSnapshots.map(
        state.team.v2RootCauseIdsByTeam[teamId] as PromiseSnapshot<RootCauseId[]>,
        (data) => uniq([...(data ?? []), rootCause.issueId]),
      ) as PromiseSnapshot<RootCauseId[]>
    }
  })
}

export async function updateRootCauseByTeam(
  context: IDashStoreContext,
  productId: ProductIds,
  teamId: TeamId,
  rootCause: Partial<IRootCauseData> & { issueId: string },
): Promise<void> {
  if (context.state.team.v2RootCauseMap[rootCause.issueId]?.status === 'done') {
    context.use.setState((state) => {
      state.team.v2RootCauseMap[rootCause.issueId] = PromiseSnapshots.done(
        assign({}, state.team.v2RootCauseMap[rootCause.issueId]!.data, rootCause),
      )
    })
  }
}

export async function clearRootCauseListByTeam(
  context: IDashStoreContext,
  productId: ProductIds,
  teamId: TeamId,
): Promise<void> {
  context.use.setState((state) => {
    delete state.team.v2RootCauseIdsByTeam?.[teamId]
  })
}
