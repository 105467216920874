import { TicketAuthType } from '@copilot-dash/domain'
import { z } from 'zod'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetAuthType } from '../../config/OptionSetAuthType'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: TicketAuthType[] | undefined
  onChangeValue: (value: TicketAuthType[]) => void
}

export const AuthTypeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = OptionSetAuthType

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-authTypeFilter'}
      filterType={getTagDisplayName('authTypes')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const parseResult = z.array(z.nativeEnum(TicketAuthType)).safeParse(item)
        if (parseResult.success) {
          onChangeValue(parseResult.data)
        }
      }}
    />
  )
}
