import { ColDef } from 'ag-grid-community'
import { AnyTableObject } from '@copilot-dash/components'

export const AdditionalColumnConfig: ColDef<AnyTableObject> = {
  headerName: '',
  field: '',
  cellRenderer: () => {
    return <></>
  },
  minWidth: 50,
  width: 100,
  sortable: false,
  resizable: true,
}
