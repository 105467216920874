import {
  AdditionalColumnType,
  AnyTableObject,
  Overlays,
  Tab,
  TabItem,
  TabItemForAnyTable,
  TabItemForMonaco,
} from '@copilot-dash/components'
import { usePromise } from '@copilot-dash/core'
import { Button } from '@fluentui/react-components'
import { SearchRegular } from '@fluentui/react-icons'
import { CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { MainForegroundForSourceBlob } from './MainForegroundForSourceBlob'
import { TicketAsyncView } from '../../../ticket/children/common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
}
export function MainForegroundForSourceTable({ ticketId }: IProps) {
  const promise = useMemo(() => {
    return app.api.copilotDash.getTicket(ticketId)
  }, [ticketId])

  const snapshot = usePromise(promise)

  const additionalColumns: AdditionalColumnType[] = [
    {
      title: 'magnifier',
      cellRenderer: (props: CustomCellRendererProps) => {
        const data: AnyTableObject = { ...props.data }
        const handleClick = () => {
          if (data.url?.valueText)
            Overlays.openAsFullDialog({
              content: <MainForegroundForSourceBlob url={data.url.valueText} ticketId={ticketId} />,
            })
        }
        return <Button icon={<SearchRegular />} onClick={handleClick} appearance="transparent" />
      },
    },
  ]
  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => (
        <Tab>
          <TabItem name="Table">
            <TabItemForAnyTable data={data.sources ?? []} additionalColumns={additionalColumns} />
          </TabItem>
          <TabItem name="JSON">
            <TabItemForMonaco data={data.sources ?? []} />
          </TabItem>
        </Tab>
      )}
    </TicketAsyncView>
  )
}
