import { Column } from '@copilot-dash/components'
import { Label } from '@fluentui/react-components'
import { memo } from 'react'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { OptionSetHitAvalon } from '../../config/OptionSetHitAvalon'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (HitAvalon: string[]) => void
}
export const HitAvalonFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  // TODO: update filterOptions to use useFilterOptions after prod DB update
  const filterOptions = OptionSetHitAvalon

  return (
    <Column>
      <Label weight="semibold">{getTagDisplayName('hitAvalon')}:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})
HitAvalonFilter.displayName = 'HitAvalonFilter'
