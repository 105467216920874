import { ProductIds } from '@copilot-dash/domain'
import { useProductIdEndpoints } from '@copilot-dash/store'
import { compact, sortBy, uniq } from 'lodash'

import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  productId: ProductIds | undefined
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const ScenarioFilter: React.FC<IProps> = ({ productId, selectedValues, onChangeValue }) => {
  const productEndpoints = useProductIdEndpoints({ productId }).data
  const scenarioList = useMemo(() => {
    const items = uniq(compact(productEndpoints?.map((endpoint) => endpoint.scenario)))

    return sortBy(items, (item) => item.toLocaleLowerCase()).map((scenario) => ({
      key: scenario,
      text: scenario,
    }))
  }, [productEndpoints])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-scenarioFilter'}
        filterType={getTagDisplayName('scenarios')}
        isGroup={true}
        optionsList={scenarioList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}
