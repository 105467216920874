import { ColDef } from 'ag-grid-community'
import { generateAnyColumnDataType } from './generateAnyColumnDataType'
import { generateAnyColumnWidth } from './generateAnyColumnWidth'
import { AnyTableObject } from '../IState'

export function generateAnyColumn(key: string, data: AnyTableObject[]): ColDef<AnyTableObject> {
  const width = generateAnyColumnWidth(key, data)
  const cellDataType = generateAnyColumnDataType(key, data)

  return {
    headerTooltip: key,
    field: key,
    cellDataType,
    tooltipField: key,
    initialWidth: width,
    editable: true,
    filter: true,
    valueGetter: (params) => {
      return params.data?.[key]?.value
    },
  }
}
