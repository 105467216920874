import { TicketAppKind } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const AppKindsFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = Object.values(TicketAppKind).map((item) => ({
    key: 'AgentAppKind:' + item,
    text: item,
  }))

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-appKindsFilter'}
      filterType={getTagDisplayName('appKinds')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
