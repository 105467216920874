import { AnyTableStore } from './AnyTableStore'
import { Root } from './children/Root'
import { AdditionalColumnType } from '../any-table-additionalColumn/AdditionalColumnType'

interface IProps<T> {
  readonly data: T[]
  readonly keyword?: string
  readonly additionalColumns?: AdditionalColumnType[]
}

export function AnyTable<T = unknown>({ data, keyword, additionalColumns }: IProps<T>) {
  return (
    <AnyTableStore data={data} keyword={keyword} additionalColumns={additionalColumns}>
      <Root />
    </AnyTableStore>
  )
}
