import { z } from 'zod'
import { generateObject } from './generateObject'
import { AnyTableObject } from '../IState'

const schema = z.array(z.record(z.unknown()))

export function generateObjects(data: unknown): AnyTableObject[] {
  const array = schema.safeParse(data).data ?? []

  return array.map((item) => {
    return generateObject(item)
  })
}
