import { Row } from '@copilot-dash/components'
import { InfoLabelProps, makeStyles, tokens } from '@fluentui/react-components'
import { useMemo, useState } from 'react'
import { CopilotDashMultiSelectDropdown } from './CopilotDashMultiSelectDropdown'
import { IFilterOption } from './IFilterOption.types'

interface IProps {
  parentFilter: string
  parentDisplayName: string
  parentOptions: IFilterOption[]
  selectedParents: string[] | undefined
  onParentChanged: (item: string[]) => void
  parentInfoLabel?: React.HTMLAttributes<HTMLElement> & InfoLabelProps['info']
  childFilter: string
  childDisplayName: string
  selectedChlidren: string[] | undefined
  onChildChanged: (item: string[]) => void
  childInfoLabel?: React.HTMLAttributes<HTMLElement> & InfoLabelProps['info']
  childCalcFunc: (dependencies: string[]) => string[]
}

export const CopilotDashDependencyDropdown: React.FC<IProps> = ({
  parentFilter,
  parentDisplayName,
  parentOptions,
  selectedParents,
  onParentChanged,
  parentInfoLabel,
  childFilter,
  childDisplayName,
  onChildChanged,
  childInfoLabel,
  selectedChlidren,
  childCalcFunc,
}: IProps) => {
  const styles = useStyles()
  const [selectedParentValues, setSelectedParentValues] = useState<string[]>([])
  const childOptionList = useMemo(() => {
    return childCalcFunc(selectedParentValues).map((child) => ({ key: child, text: child }))
  }, [childCalcFunc, selectedParentValues])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={`combo-${parentFilter}Filter`}
        filterType={parentDisplayName}
        isGroup={true}
        optionsList={parentOptions}
        defaultSelectedOption={selectedParents}
        onChangeFilter={(items) => {
          onParentChanged(items)
          const newDependentList = childCalcFunc(items)
          // Filterout dependents that not available to new dependencies
          onChildChanged(
            items.length ? selectedChlidren?.filter((dependent) => newDependentList.includes(dependent)) ?? [] : [],
          )
          setSelectedParentValues(items)
        }}
        infoLabelContent={parentInfoLabel}
      />
      {!!selectedParentValues?.length && (
        <Row>
          <div className={styles.dotLeft} />
          <div className={styles.dotRight} />
          <CopilotDashMultiSelectDropdown
            comboId={`combo-${childFilter}Filter`}
            filterType={childDisplayName}
            isGroup={true}
            optionsList={childOptionList}
            defaultSelectedOption={selectedChlidren}
            onChangeFilter={onChildChanged}
            infoLabelContent={childInfoLabel}
            className={styles.dependent}
          />
        </Row>
      )}
    </>
  )
}

const useStyles = makeStyles({
  dependent: {
    width: '90%',
    marginLeft: '1%',
  },
  dotLeft: {
    borderLeft: `2px dotted ${tokens.colorNeutralStroke1}`,
    borderBottom: `2px dotted ${tokens.colorNeutralStroke1}`,
    width: '4%',
    marginLeft: '4%',
    height: '40px',
  },
  dotRight: {
    width: '4px',
    height: '4px',
    backgroundColor: tokens.colorNeutralStroke1,
    borderRadius: '50%',
    marginTop: '37px',
    marginLeft: '2px',
  },
})
