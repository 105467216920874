import { TicketRingType } from '@copilot-dash/domain'
import { z } from 'zod'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetRing } from '../../config/OptionSetRing'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: TicketRingType[] | undefined
  onChangeValue: (value: TicketRingType[]) => void
}

export const RingFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = OptionSetRing

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-ringFilter'}
      filterType={getTagDisplayName('ring')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const ring = z.array(z.nativeEnum(TicketRingType)).safeParse(item).data
        if (ring) {
          onChangeValue(ring)
        }
      }}
    />
  )
}
