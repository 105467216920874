import { Row } from '@copilot-dash/components'
import { WorkItemStatus } from '@copilot-dash/domain'
import { SearchFacetPrefix } from '@copilot-dash/store'
import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { FC, memo, useEffect, useState } from 'react'
import { ICellBaseProps } from './types'
import {
  RatioProgressBar,
  RatioProgressBarProps,
} from '../../../../../../../components/RatioProgressBar/RatioProgressBar'

import { useSearchScreenState } from '../../../../../store'

interface IStateProps extends ICellBaseProps {
  tableTotalTicketStatusCount: number | undefined
}
const MIN_WIDTH_PERCENTAGE = 5
export const State: FC<IStateProps> = memo(({ data, tableTotalTicketStatusCount }) => {
  const [loading, setLoading] = useState(true)
  const product = useSearchScreenState((state) => state.form.product)
  const [ticketStatusDistribution, setTicketStatusDistribution] = useState<{
    closed?: number
    rootCaused?: number
    teamAssigned?: number
    untriaged?: number
  }>({})

  useEffect(() => {
    app.store.actions
      .searchTickets(
        {
          product,
          count: 0,
          offset: 0,
          clusteringIssueId: data.issueId,
          batchId: data.batchId,
        },
        [SearchFacetPrefix.workItemStatus],
      )
      .then((res) => {
        const ticketStatusDistribution = res.facetResults?.[SearchFacetPrefix.workItemStatus] ?? {}
        function getCountByStatuses(statuses: WorkItemStatus[]) {
          return statuses.reduce((acc, status) => {
            const statusCount = ticketStatusDistribution[status] ?? 0
            return acc + statusCount
          }, 0)
        }
        setTicketStatusDistribution({
          closed: getCountByStatuses([
            WorkItemStatus.ClosedByDesign,
            WorkItemStatus.ClosedFixed,
            WorkItemStatus.ClosedNotActionable,
          ]),
          rootCaused: getCountByStatuses([WorkItemStatus.RootCaused]),
          teamAssigned: getCountByStatuses([WorkItemStatus.TeamAssigned]),
          untriaged: getCountByStatuses([WorkItemStatus.Untriaged]),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [data.batchId, data.issueId, product])

  const styles = useStyles()
  if (loading || data.extendInfo.status === 'waiting') {
    return (
      <Skeleton style={{ width: '100%' }} aria-label="Loading Content">
        <SkeletonItem />
      </Skeleton>
    )
  }

  const ratioProgressBarData: RatioProgressBarProps = {
    combinedTotal: tableTotalTicketStatusCount ?? 100,
    minWidthPercentage: MIN_WIDTH_PERCENTAGE,
    items: [
      {
        value: ticketStatusDistribution.closed ?? 0,
        color: tokens.colorPaletteGreenBackground2,
        label: 'Closed',
      },
      {
        value: ticketStatusDistribution.rootCaused ?? 0,
        color: tokens.colorCompoundBrandBackground,
        label: 'Root Caused',
      },
      {
        value: ticketStatusDistribution.teamAssigned ?? 0,
        color: tokens.colorPaletteMarigoldBackground3,
        label: 'Team Assigned',
      },
      {
        value: ticketStatusDistribution.untriaged ?? 0,
        color: tokens.colorNeutralBackground6,
        label: 'Untriaged',
      },
    ],
  }

  return (
    <Row className={styles.container}>
      <RatioProgressBar
        combinedTotal={ratioProgressBarData.combinedTotal}
        minWidthPercentage={ratioProgressBarData.minWidthPercentage}
        items={ratioProgressBarData.items}
      />
    </Row>
  )
})

const useStyles = makeStyles({
  container: {
    display: 'block',
    width: '100%',
  },
})

State.displayName = 'State'
