import { PromiseSnapshots, usePromise } from '@copilot-dash/core'
import { RootCauseId } from '@copilot-dash/domain'
import { getListByIds, SearchFacetPrefix } from '@copilot-dash/store'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { SUPPORTED_TAGS } from '../../../../components/TicketsFilterPanel/config/SupportedTags'
import { isValidFormValue } from '../../../../components/TicketsFilterPanel/config/utils'
import { useTeamViewStore } from '../../store'

export function useRootCauseList() {
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const refreshRootCauseCounter = useTeamViewStore((state) => state.rootCauses.refreshRootCauseCounter)

  const searchByTicketCharacterTriggered =
    SUPPORTED_TAGS.filter((tag) => {
      const value = form[tag.key]
      return isValidFormValue(value)
    }).length > 0 ||
    !!form.range ||
    !!(form.tenantIds && form.tenantIds.length > 0)

  const [rootCauseIdsPromise, setRootCauseIdsPromise] = useState<Promise<[RootCauseId, number][]>>(Promise.resolve([]))
  const rootCauseIdsByTeamSnapshot = application.store.use((state) =>
    teamId ? state.team.v2RootCauseIdsByTeam[teamId] : undefined,
  )
  const rootCauseMapSnapshot = application.store.use((state) => state.team.v2RootCauseMap)
  const rootCauseIdsSnapshot = usePromise(rootCauseIdsPromise)
  const LinkedRootCauseListSnapshot = useMemo(() => {
    if (rootCauseIdsSnapshot?.status === 'done' && rootCauseIdsByTeamSnapshot?.status === 'done') {
      return PromiseSnapshots.map(
        getListByIds(
          rootCauseIdsSnapshot.data
            .map((item) => item[0])
            .filter((rcId) => rootCauseIdsByTeamSnapshot.data.includes(rcId)),
          rootCauseMapSnapshot,
        ),
        (data) => ({
          rootCauseList: data.map((rc) => ({
            ...rc,
            rootCauseCount: rootCauseIdsSnapshot.data.find((item) => item[0] === rc.issueId)?.[1] ?? 0,
          })),
        }),
      )
    }
    return PromiseSnapshots.waiting(new Promise(() => {}))
  }, [rootCauseIdsByTeamSnapshot, rootCauseIdsSnapshot, rootCauseMapSnapshot])

  const allRootCauseListSnapshot = useMemo(() => {
    if (rootCauseIdsSnapshot.status === 'done' && rootCauseIdsByTeamSnapshot?.status === 'done') {
      return PromiseSnapshots.map(getListByIds(rootCauseIdsByTeamSnapshot.data, rootCauseMapSnapshot), (data) => ({
        rootCauseList: data.map((rc) => ({
          ...rc,
          rootCauseCount: rootCauseIdsSnapshot.data.find((item) => item[0] === rc.issueId)?.[1] ?? 0,
        })),
      }))
    }
    return PromiseSnapshots.waiting(new Promise(() => {}))
  }, [rootCauseIdsByTeamSnapshot, rootCauseIdsSnapshot, rootCauseMapSnapshot])

  const refreshRootCauseList = useCallback(() => {
    if (!searchByTicketCharacterTriggered) {
      productId && teamId && app.store.actions.clearRootCauseListByTeam(productId, teamId)
    }
    useTeamViewStore.getState().rootCauses.updateRootCauseCounter()
  }, [productId, searchByTicketCharacterTriggered, teamId])

  useEffect(() => {
    if (productId && teamId) {
      application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
    }
  }, [productId, teamId, refreshRootCauseCounter])

  useEffect(() => {
    if (teamId) {
      setRootCauseIdsPromise(
        application.store.actions
          .searchTickets({ ...form, defaultRange: undefined, teamId, offset: 0, count: 0 }, [
            `${SearchFacetPrefix.relatedWorkItemIds},count:1000`, // limit to 1000 for performance
          ])
          .then((response) => {
            const rootCauseIdFacet = response.facetResults?.[SearchFacetPrefix.relatedWorkItemIds] ?? {}
            const rootCauseIds = Object.keys(rootCauseIdFacet)
            return rootCauseIds
              .map((issueId) => [issueId, rootCauseIdFacet[issueId]])
              .filter((item): item is [RootCauseId, number] => {
                const count = item[1]
                return typeof count === 'number' && count > 0
              })
          }),
      )
    }
  }, [form, teamId])

  return [LinkedRootCauseListSnapshot, allRootCauseListSnapshot, refreshRootCauseList] as const
}
