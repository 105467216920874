import { Row, Spacer } from '@copilot-dash/components'
import { TeamViewSubMenuIds } from '@copilot-dash/domain'
import { ISearchTicketArgs } from '@copilot-dash/store'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  Button,
  Divider,
  SearchBox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { debounce, isNil } from 'lodash'
import { memo, useCallback, useMemo, useState, type MouseEvent } from 'react'
import { AssignedToSearch } from './AssignedToSearch'
import { columnsConfig } from './columns'
import { RootCauseMultiselect } from './RootCauseMultiselect'
import { useStyles } from './RootCauseTable.styles'
import { useRootCauseList } from './useRootCauseList'
import { AdaptiveBar } from '../../../../components/AdaptiveBar/AdaptiveBar'
import { TicketsFilterButton } from '../../../../components/Button/TicketsFilterButton'
import { ErrorView } from '../../../../components/Error'
import { RootCauseCreateDialog } from '../../../../components/RootCauseCreateDialog/RootCauseCreateDialog'
import {
  FieldNames,
  IFormData as ICreateRootCauseFormData,
} from '../../../../components/RootCauseCreateDialog/RootCauseCreateForm'
import { OverflowTabList } from '../../../../components/TabList/OverflowTabList'
import { SUPPORTED_TAGS } from '../../../../components/TicketsFilterPanel/config/SupportedTags'
import { useToast } from '../../../../hooks/useToast'
import { RouteLink, TeamRoute } from '../../../../router'
import { useGlobalStore } from '../../../../store'
import { ClarityScenarioIds, useClaritySectionId } from '../../../../utils/useClarityPageId'
import { useTeamViewStore } from '../../store'
import { EMPTY_TEAM_ID } from '../../store/const'
import { TableLoading } from '../TableLoading'
import { ToggleLeftDrawerIcon } from '../ToggleDrawerLeftIcon'

enum RootCauseFilterTab {
  LinkedRootCauses = 'Linked with DSATs',
  AllRootCauses = 'All Root Causes',
}
const ROOT_CAUSE_TABS_OPTIONS = [RootCauseFilterTab.LinkedRootCauses, RootCauseFilterTab.AllRootCauses]
const priorityOptions = ['P1', 'P2', 'P3', 'P4']
const stateOptions = ['New', 'Active', 'Closed']
export const RootCauseTable = memo(() => {
  useClaritySectionId(ClarityScenarioIds.menuRootCauses)
  const styles = useStyles()
  const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
  const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeamName)
  const [showDivider, setShowDivider] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState<RootCauseFilterTab>(RootCauseFilterTab.LinkedRootCauses)

  const [LinkedRootCauseSnapshot, allRootCauseListSnapshot, refreshRootCauseList] = useRootCauseList()

  const [searchInput, setSearchInput] = useState('')
  const [priority, setPriority] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<string[]>([])
  const [state, setState] = useState<string[]>([])
  const [sort, setSort] = useState<{ columnId: string; direction: 'ascending' | 'descending' } | null>({
    columnId: 'rootCauseStatus',
    direction: 'ascending',
  })

  const emails: string[] = useMemo(() => {
    if (allRootCauseListSnapshot && allRootCauseListSnapshot.status === 'done') {
      const full = allRootCauseListSnapshot.data.rootCauseList
      const validEmail = full.filter((item) => item.owner).map((item) => item.owner)
      const uniqueArr = Array.from(new Set(validEmail))
      return uniqueArr as string[]
    }
    return []
  }, [allRootCauseListSnapshot])

  const tableItems = useMemo(() => {
    const rootCauseListSnapshot =
      currentTab === RootCauseFilterTab.LinkedRootCauses ? LinkedRootCauseSnapshot : allRootCauseListSnapshot
    if (rootCauseListSnapshot && rootCauseListSnapshot.status === 'done') {
      const full = rootCauseListSnapshot.data.rootCauseList

      const list = full
        .filter((rootCause) => rootCause.visible)
        .filter((rootCause) => rootCause.rootCauseTitle.toLowerCase().includes(searchInput.trim().toLowerCase()))
        .filter((rootCause) => {
          const validPriority = priority.map((item) => Number(item[1]))
          if (validPriority.length > 0) {
            return rootCause.rootCausePriority && validPriority.includes(rootCause.rootCausePriority)
          } else {
            return true
          }
        })
        .filter((rootCause) => {
          if (assignedTo.length === 0) return true
          return rootCause.owner && assignedTo.includes(rootCause.owner)
        })
        .filter((rootCause) => {
          if (state.length === 0) return true
          return rootCause.rootCauseStatus && state.includes(rootCause.rootCauseStatus)
        })

      if (sort) {
        list.sort((a, b) => {
          const column = columnsConfig.find((c) => c.id === sort.columnId)
          if (column && column.onSort) {
            return column.onSort(a, b, sort.direction)
          }
          return 0
        })
      }
      return list
    }
    return []
  }, [currentTab, LinkedRootCauseSnapshot, allRootCauseListSnapshot, sort, searchInput, priority, assignedTo, state])

  const headerSortProps = useCallback(
    (columnId: string) => {
      return {
        onClick: (e: MouseEvent) => {
          const newSort =
            sort && sort.columnId === columnId
              ? {
                  columnId,
                  direction: sort.direction === 'ascending' ? ('descending' as const) : ('ascending' as const),
                }
              : { columnId, direction: 'ascending' as const }
          setSort(newSort)

          // Log telemetry for sorting
          Logger.telemetry.trackEvent('RootCause/Sort', { columnId: columnId, teamName: teamName ?? undefined })
        },
        sortDirection: sort && sort.columnId === columnId ? sort.direction : undefined,
      }
    },
    [sort, teamName],
  )

  const updateSelectedTab = useCallback((tab: React.SetStateAction<RootCauseFilterTab>) => {
    setCurrentTab(tab)
  }, [])

  const filters = useTeamViewStore((state) => state.tickets.filterForm)
  const generatePath = useCallback(
    (issueId: string) => {
      const product = useTeamViewStore.getState().productName
      const teamName = useTeamViewStore.getState().teams.lastSelectedTeamName
      // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      const path = TeamRoute.navigator.generatePath({
        ...filters,
        product,
        subMenu: TeamViewSubMenuIds.RootCauses,
        teamName: teamName || useTeamViewStore.getState().route.args['teamName'],
        issueId,
      })
      return path
    },
    [filters],
  )

  const table = useMemo(() => {
    const rootCausesSnapshot =
      currentTab === RootCauseFilterTab.LinkedRootCauses ? LinkedRootCauseSnapshot : allRootCauseListSnapshot
    if (!rootCausesSnapshot || rootCausesSnapshot.status === 'waiting') {
      return <TableLoading />
    }
    if (rootCausesSnapshot.status === 'error') {
      return <ErrorView error={rootCausesSnapshot.error} />
    }
    if (rootCausesSnapshot.status === 'done') {
      return tableItems.length > 0 ? (
        <div>
          <Table noNativeElements aria-label="Root Causes" className={styles.table}>
            <TableHeader>
              <TableRow className={styles.headerRow}>
                {columnsConfig.map((column) => (
                  <TableHeaderCell
                    sortable={column.onSort ? true : false}
                    {...(column.onSort ? headerSortProps(column.id) : {})}
                    key={column.id}
                    style={column.cellStyle}
                  >
                    {column.renderHeaderCell()}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableItems.map((rootCause) => {
                return (
                  <RouteLink path={generatePath(rootCause.issueId)} key={rootCause.issueId}>
                    <TableRow key={rootCause.issueId} className={styles.bodyRow}>
                      {columnsConfig.map((column) => (
                        <TableCell key={column.id} style={column.cellStyle}>
                          {column.renderCell(rootCause)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </RouteLink>
                )
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <ErrorView.Custom level="WARNING" message="No root causes found" />
      )
    }
    return null
  }, [
    currentTab,
    LinkedRootCauseSnapshot,
    allRootCauseListSnapshot,
    tableItems,
    styles.table,
    styles.headerRow,
    styles.bodyRow,
    headerSortProps,
    generatePath,
  ])

  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const filterPanelOpened = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const supportedTagsForRootCause = [
    ...SUPPORTED_TAGS,
    { key: 'range' as keyof ISearchTicketArgs, displayName: 'Time range' },
    { key: 'tenantIds' as keyof ISearchTicketArgs, displayName: 'Tenant' },
  ]
  const validTags = supportedTagsForRootCause.filter((tag) => {
    const value = form[tag.key]

    if (value) {
      if (Array.isArray(value)) {
        return value.length > 0
      } else {
        return true
      }
    } else {
      return false
    }
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logSearchInput = useCallback(
    debounce((input) => {
      Logger.telemetry.trackEvent('RootCause/Filter', {
        filterName: 'Search',
        teamName: teamName ?? undefined,
        filter: { search: input },
      })
    }, 300),
    [],
  )

  const toast = useToast()

  const handleSaveAndAdd = useCallback(
    async (data: ICreateRootCauseFormData) => {
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return
      let formattedDate = ''
      if (data[FieldNames.DUEDATE]) {
        const timeStr = String(data[FieldNames.DUEDATE])
        const date = new Date(timeStr)

        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        formattedDate = `${year}-${month}-${day}`
      }

      return application.store.actions
        .createRootCause({
          rootCauseTitle: data.title,
          description: '',
          rootCausePriority: data.priority,
          teamId: teamId,
          owner: data[FieldNames.ASSIGNEDTO],
          eTA: formattedDate,
          rootCauseStatus: data[FieldNames.STATE],
        })
        .then((response) => {
          if (response.workItemId) {
            // NOTE: @Ethan - Update the root cause list in the store.
            if (teamName) {
              useGlobalStore.getState().addRootCauseListByAreaPath(teamName, {
                issueId: response.workItemId,
                rootCauseTitle: data.title,
                visible: true,
                rootCauseStatus: 'Active',
                createdTimeUtc: formattedDate,
                teamId: teamId,
                rootCauseType: 'Issue',
              })
            }
            if (productId && teamId) {
              application.store.actions.appendRootCauseByTeam(productId, teamId, {
                issueId: response.workItemId,
                rootCauseTitle: data.title,
                rootCausePriority: data.priority,
                rootCauseStatus: data[FieldNames.STATE],
                eTA: formattedDate,
                owner: data[FieldNames.ASSIGNEDTO],
                createdTimeUtc: new Date().toLocaleDateString(),
                rootCauseCount: 0,
                enable: false,
                visible: true,
                teamId: teamId,
                rootCauseType: 'Issue',
              })
            }
            setDialogOpen(false)
            refreshRootCauseList()
          } else {
            // handle error
            toast.showError('Failed to save', response.errorMessage)
          }
        })
        .catch((err) => {
          toast.showError('Failed to save', err.message)
        })
    },
    [teamId, teamName, productId, toast, refreshRootCauseList],
  )

  return (
    <>
      <Row className={styles.header}>
        {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbButton size="large" style={{ height: 'unset', minWidth: 80, padding: 0 }}>
              <Text size={500} weight="semibold">
                Root Causes
              </Text>
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <Button
          className={styles.addBtn}
          appearance="transparent"
          onClick={() => setDialogOpen(true)}
          icon={<AddRegular fontSize={18} />}
        >
          Add root cause
        </Button>
      </Row>
      <Row style={{ minWidth: '720px' }}>
        <SearchBox
          className={styles.search}
          value={searchInput}
          onChange={(_, data) => {
            setSearchInput(data.value)
            logSearchInput(data.value)
          }}
          placeholder="Search root cause"
        />

        <AdaptiveBar className={styles.adaptiveFilter} onNavShow={(show) => setShowDivider(show)}>
          <Row>
            <RootCauseMultiselect
              comboId="prioritySearch"
              defaultValue={priority}
              options={priorityOptions}
              placeholder="Priority"
              onChangeFilter={(data) => {
                setPriority(data)
                // Log telemetry for filter
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'Priority',
                  teamName: teamName ?? undefined,
                  filter: { priority: data },
                })
              }}
            />
            <Spacer width={8} />
          </Row>
          <Row>
            <AssignedToSearch
              users={assignedTo}
              onChangeUser={(data) => {
                setAssignedTo(data)
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'AssignTo',
                  teamName: teamName ?? undefined,
                  filter: { assignTo: data },
                })
              }}
              emails={emails}
            />
            <Spacer width={8} />
          </Row>
          <Row>
            <RootCauseMultiselect
              comboId="stateSearch"
              defaultValue={state}
              options={stateOptions}
              placeholder="State"
              onChangeFilter={(data) => {
                setState(data)
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'State',
                  teamName: teamName ?? undefined,
                  filter: { state: data },
                })
              }}
            />
            <Spacer width={8} />
          </Row>
        </AdaptiveBar>
        {showDivider ? (
          <Divider vertical style={{ height: '100%', width: '16px', flexGrow: 0 }} />
        ) : (
          <Spacer width={16} />
        )}
        <TicketsFilterButton
          placeholder={'Feedback Filters'}
          isTicketsFilterPanelOpen={filterPanelOpened}
          onClickButton={() => {
            useTeamViewStore.getState().toggleFilterPanel((bool) => !bool)
          }}
          badgeCount={validTags.length}
        />
      </Row>
      <Spacer height={16} />
      <OverflowTabList
        items={ROOT_CAUSE_TABS_OPTIONS}
        renderItem={(item) => (
          <>
            {item}
            {/* {renderItemCount(item)} */}
          </>
        )}
        onSelect={updateSelectedTab}
      />
      <Spacer height={4} />
      {table}
      <RootCauseCreateDialog
        fields={[FieldNames.PRIORITY, FieldNames.TITLE, FieldNames.STATE, FieldNames.DUEDATE, FieldNames.ASSIGNEDTO]}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onOk={handleSaveAndAdd}
        okBtnText="Save and Add"
        title="Create new root cause"
      />
    </>
  )
})

RootCauseTable.displayName = 'RootCauseTable'
