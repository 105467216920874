import { TicketAuthType } from '@copilot-dash/domain'
import { IFilterOption } from '../../Filter/IFilterOption.types'

export const OptionSetAuthType: IFilterOption[] = [
  {
    key: TicketAuthType.AAD,
    text: TicketAuthType.AAD,
  },
  {
    key: TicketAuthType.MSA,
    text: TicketAuthType.MSA,
  },
]
