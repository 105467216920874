import { sortBy, uniq } from 'lodash'
import { AnyTableObject } from '../IState'

/**
 * This number represents that the first "3" rows will be used as the data source,
 * and then all keys of these three rows will be taken out and these keys wil be the final column keys.
 */
const SAMPLE_ROW_COUNT = 3

export function generateAnyColumnKeys(objects: AnyTableObject[]): string[] {
  const keys: string[] = []
  for (let i = 0; i < SAMPLE_ROW_COUNT; i++) {
    keys.push(...Object.keys(objects[i] ?? {}))
  }

  return sortBy(uniq(keys), (key) => {
    const lowerCaseKey = key.toLowerCase()
    if (lowerCaseKey.includes('timestamp')) return 0
    if (lowerCaseKey.includes('time')) return 1
    if (lowerCaseKey.includes('date')) return 2
    return 3
  })
}
