import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import {
  getProductIdByName,
  getProductNameById,
  IProductData,
  ProductIds,
  TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST,
  TeamViewSubMenuIds,
} from '@copilot-dash/domain'
import { TeamViewSearchTicketArgsNames } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RootCauseRouteArgs } from './RootCauseRouteMapper'
import { ITeamViewRouteArgs } from './types'
import { ErrorView } from '../../../components/Error'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { TeamScreen } from '../../../screens/team/TeamScreen'
import { createNavigator } from '../../navigator/createNavigator'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'

interface IRootCausePageProps {
  rootCauseNavigator: ReturnType<typeof createNavigator<RootCauseRouteArgs>>
  teamViewNavigator: ReturnType<typeof createNavigator<ITeamViewRouteArgs>>
}

export function RootCausePage(props: IRootCausePageProps) {
  const { rootCauseNavigator, teamViewNavigator } = props
  const rootCauseNavArgs = rootCauseNavigator.useArgs()
  const teamsByProductId = app.store.use((state) => state.team.teams)
  const [productIdSnapshot, setProductIdSnapshot] = useState<PromiseSnapshot<ProductIds>>(
    PromiseSnapshots.waiting(Promise.resolve(ProductIds.M365Chat)),
  )

  const handleSelectProduct = useCallback(
    (product: IProductData) => {
      teamViewNavigator.navigate({
        product: product.name,
      })
    },
    [teamViewNavigator],
  )

  useEffect(() => {
    app.store.actions.getRootCauseInfoByIdV2(rootCauseNavArgs.rootCauseId)
    for (const productName of TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST) {
      const productId = getProductIdByName(productName)
      productId && app.store.actions.getTeams(productId)
    }
  })

  const rootCauseInfoSnapshotV2 = app.store.use((state) => state.team.v2RootCauseMap?.[rootCauseNavArgs.rootCauseId])

  const teamsMapSnapshotV2 = app.store.use.getTeamsMap()

  useEffect(() => {
    if (rootCauseInfoSnapshotV2?.status === 'done') {
      const rootCauseInfo = rootCauseInfoSnapshotV2.data
      for (const productName of TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST) {
        const productId = getProductIdByName(productName)
        if (
          productId &&
          teamsByProductId?.[productId]?.status === 'done' &&
          (teamsByProductId?.[productId]?.data ?? []).find((team) => team.id === rootCauseInfo.teamId)
        ) {
          setProductIdSnapshot(PromiseSnapshots.done(productId))
          break
        }
      }
    }
  }, [rootCauseInfoSnapshotV2, teamsByProductId])

  const args = useMemo(() => {
    if (!rootCauseInfoSnapshotV2?.data) {
      return null
    }
    if (productIdSnapshot.status !== 'done') return null
    if (!teamsMapSnapshotV2.data) return null
    const productId = productIdSnapshot.data
    const rootCauseInfo = rootCauseInfoSnapshotV2.data
    const teamName = teamsMapSnapshotV2.data?.[rootCauseInfo.teamId]?.name
    const productName = getProductNameById(productId)
    if (!productName) {
      return null
    }
    return {
      product: productName,
      subMenu: TeamViewSubMenuIds.RootCauses,
      [TeamViewSearchTicketArgsNames.issueId]: rootCauseInfo.issueId,
      [TeamViewSearchTicketArgsNames.teamId]: rootCauseInfo.teamId,
      [TeamViewSearchTicketArgsNames.teamName]: teamName,
    }
  }, [productIdSnapshot, rootCauseInfoSnapshotV2, teamsMapSnapshotV2])

  if (
    productIdSnapshot.status === 'waiting' ||
    rootCauseInfoSnapshotV2?.status === 'waiting' ||
    teamsMapSnapshotV2.status === 'waiting'
  ) {
    return <Spinner>Loading...</Spinner>
  }
  if (rootCauseInfoSnapshotV2?.status === 'error' || teamsMapSnapshotV2.status === 'error') {
    return <ErrorView error={rootCauseInfoSnapshotV2?.error ?? teamsMapSnapshotV2.error} />
  }

  if (args && productIdSnapshot.status === 'done' && productIdSnapshot.data) {
    return (
      <GlobalProductSelector
        value={args.product}
        onChange={handleSelectProduct}
        isItemDisabled={(product) => !TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product.name)}
      >
        <PageTitle title="Team">
          <TeamScreen key={args.product} args={args} productId={productIdSnapshot.data} />
        </PageTitle>
      </GlobalProductSelector>
    )
  }

  return <NotFoundRoutePage />
}
