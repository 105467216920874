import { OverViewTabs, IQuery } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { ISearchTicketArgs } from '@copilot-dash/store'
import { SearchScreenLayout } from './layout/SearchScreenLayout'
import { SearchScreenStoreProvider } from './store'

export interface ISearchScreenProps {
  readonly query: ISearchScreenPropsQuery
  readonly onNavigate: (query: ISearchScreenPropsQuery) => void
  readonly currentQuery?: IQuery
}

export interface ISearchScreenPropsQuery extends ISearchTicketArgs {
  readonly tab?: OverViewTabs
  readonly issueBatchId?: string
  readonly queryId?: string
}

export function SearchScreen(props: ISearchScreenProps) {
  return (
    <TelemetryScope scope="SearchScreen">
      <SearchScreenStoreProvider props={props}>
        <SearchScreenLayout />
      </SearchScreenStoreProvider>
    </TelemetryScope>
  )
}
