import { DefaultInvocationTypeMapping } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (InvocationTypes: string[]) => void
}

export const InvocationTypeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const filterOptions: IFilterOption[] = Object.entries(DefaultInvocationTypeMapping).map(([key, text]) => ({
    key,
    text,
  }))

  return (
    <CopilotDashMultiSelectDropdown
      comboId="InvocationTypeFilter"
      filterType={getTagDisplayName('invocationType')}
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
