import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { AgGridReact } from 'ag-grid-react'
import { useRef } from 'react'
import { Column, useThemeMode } from '@copilot-dash/components'
import { AnyTableStore } from '../../AnyTableStore'
import { AnyTableObject } from '../../store/IState'

const THEME_CLASS_LIGHT = 'ag-theme-quartz'
const THEME_CLASS_DARK = 'ag-theme-quartz-dark'

export function Body() {
  const ref = useRef<AgGridReact<AnyTableObject>>(null)
  const styles = useStyles()
  const objects = AnyTableStore.use((state) => state.objects)
  const columns = AnyTableStore.use((state) => state.columns)

  const keyword = AnyTableStore.use((state) => state.keyword)
  const actions = AnyTableStore.useActions()

  const themeMode = useThemeMode()
  const themeClass = themeMode === 'dark' ? THEME_CLASS_DARK : THEME_CLASS_LIGHT

  return (
    <Column className={mergeClasses(themeClass, styles.root)} fill>
      <AgGridReact
        ref={ref}
        rowData={objects}
        columnDefs={columns}
        rowSelection={{
          mode: 'multiRow',
          headerCheckbox: false,
          checkboxes: false,
          enableClickSelection: true,
        }}
        readOnlyEdit={true}
        quickFilterText={keyword}
        cacheQuickFilter={true}
        onFilterChanged={() => {
          const instance = ref.current
          if (instance) {
            const visibleRows = instance.api.getDisplayedRowCount()
            actions.updateSearchResultCount(visibleRows)
          }
        }}
      />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    overflow: 'hidden',

    '& .ag-root-wrapper': {
      borderRadius: '0',
    },
  },
})
