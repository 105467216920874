import { Times, listenPromise } from '@copilot-dash/core'

import { NewSearchCopilotTicketsResult } from '@copilot-dash/store'
import { ISearchScreenStore } from '../ISearchScreenStore'

const PAGE_SIZE = 50

export class SearchLoadMoreTicketsAction {
  private readonly store: ISearchScreenStore
  private readonly loadMorePromise: { [key: number]: Promise<NewSearchCopilotTicketsResult> }
  private endTime: string

  constructor(store: ISearchScreenStore) {
    this.store = store
    this.loadMorePromise = {}
    this.endTime = new Date().toISOString()
  }

  clearLoadMorePromise(): void {
    for (const key in this.loadMorePromise) {
      delete this.loadMorePromise[key]
    }
  }

  loadMoreTickets(offset: number): void {
    if (this.loadMorePromise[offset] || !this.store.state.searchFeedbackResult.hasMore) return
    if (offset === 0) {
      this.endTime = new Date().toISOString()
    }
    const form = this.store.state.form
    let finalTimeRange = form.range || form.defaultRange

    const { from, to } = Times.formatTimeRange(finalTimeRange, { timezone: 'UTC' })

    if (!to || to > this.endTime) {
      finalTimeRange = { type: 'absolute', from: from ?? '', to: this.endTime }
    }

    const newLoadMorePromisePromise = app.store.actions.searchTickets({
      ...form,
      count: PAGE_SIZE,
      offset: offset,
      isAIF: form.product === 'TenantAdminFeedback',
      range: finalTimeRange,
    })

    this.loadMorePromise[offset] = newLoadMorePromisePromise

    try {
      listenPromise({
        promise: newLoadMorePromisePromise,
        isAlive: () => {
          return newLoadMorePromisePromise === this.loadMorePromise[offset]
        },
        onUpdated: (snapshot) => {
          this.store.setState({
            loadMoreSnapshot: {
              ...this.store.state.loadMoreSnapshot,
              [offset]: snapshot,
            },
          })
        },
        onSuccess: (snapshot) => {
          app.store.actions.batchUpdateTickets(snapshot.data?.tickets ?? [])

          const existingTickets = new Set(this.store.state.searchFeedbackResult.ticketIds || [])
          const mergedTickets = [...existingTickets]
          mergedTickets.push(
            ...(snapshot.data?.tickets || [])
              .map((item) => item.ticketId)
              .filter((item): item is string => !!item && !existingTickets.has(item)),
          )

          this.store.setState({
            searchFeedbackResult: {
              ticketIds: mergedTickets,
              total: snapshot.data?.ticketCount || 0,
              hasMore: snapshot.data?.hasMore || false,
            },
          })
        },
      })
    } catch (error) {
      this.store.setState({
        loadMoreSnapshot: {
          ...this.store.state.loadMoreSnapshot,
          [offset]: {
            status: 'error',
            error: error,
          },
        },
      })
    }
  }
}
