import { ProductIds, TeamId } from '@copilot-dash/domain'
import { Body1Stronger } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { FeedbackHandlingCardFilters } from './config'
import { useTicketFilterStore } from '../../store/store'
import { CustomTagsFilter, DSATStatusFilter, HasAssigneeFilter, PriorityFilter, TeamIdsFilter } from '../filters'
import { AssignedToFilter } from '../filters/AssignedToFilter'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
  productId?: ProductIds
  teamId?: TeamId
  stateDisabled?: boolean
}

export function FeedbackHandlingCard({ control, productId, teamId, stateDisabled }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

  const hasFeedbackHandlingCard = useMemo(() => {
    return filterPanelItems.some((item) => FeedbackHandlingCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackHandlingCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Feedback handling</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes('hasAssignee') && (
          <Controller
            name="hasAssignee"
            control={control}
            render={({ field }) => <HasAssigneeFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {filterPanelItems?.includes('priority') && (
          <Controller
            name="priority"
            control={control}
            render={({ field }) => <PriorityFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('dSATStatus') && (
          <Controller
            name="dSATStatus"
            control={control}
            render={({ field }) => (
              <DSATStatusFilter selectedValues={field.value} onChangeValue={field.onChange} disabled={stateDisabled} />
            )}
          />
        )}

        {filterPanelItems?.includes('dSATAssignedTo') && (
          <Controller
            name="dSATAssignedTo"
            control={control}
            render={({ field }) => (
              <AssignedToFilter
                selectedValues={field.value}
                onChangeValue={field.onChange}
                productId={productId}
                teamId={teamId}
              />
            )}
          />
        )}

        {filterPanelItems?.includes('teamIds') && (
          <Controller
            name="teamIds"
            control={control}
            render={({ field }) => <TeamIdsFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('customTags') && (
          <Controller
            name="customTags"
            control={control}
            render={({ field }) => <CustomTagsFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
