import { ColDef } from 'ag-grid-community'
import { AnyTableObject, IState } from './IState'
import { AdditionalColumnType } from '../../any-table-additionalColumn/AdditionalColumnType'

interface IParams {
  readonly data: unknown[]
  readonly objects: AnyTableObject[]
  readonly columns: ColDef<AnyTableObject, unknown>[]
  readonly keyword: string | undefined
  readonly additionalColumns: AdditionalColumnType[] | undefined
}

export function createState({ data, objects, columns, keyword, additionalColumns }: IParams): IState {
  return {
    data,
    objects: objects,
    columns: columns,
    keyword: keyword,
    searchResultCount: undefined,
    additionalColumns,
  }
}
