export enum CapabilitiesSlicer {
  CustomActions = 'CustomActions',
  CustomActionsOnly = 'CustomActionsOnly',
  OneDriveAndSharePointCapability = 'OneDriveAndSharePointCapability',
  GraphConnectorsCapability = 'GraphConnectorsCapability',
  WebSearchCapability = 'WebSearchCapability',
  GraphicArtCapability = 'GraphicArtCapability',
  CodeInterpreterCapability = 'CodeInterpreterCapability',
  GraphConnectorsOnly = 'GraphConnectorsOnly',
  InstructionsOnly = 'InstructionsOnly',
  OneDriveAndSharePointOnly = 'OneDriveAndSharePointOnly',
  SharePointCapability = 'SharePointCapability',
  DataverseCapability = 'DataverseCapability',
  ClipchampCapability = 'ClipchampCapability',
  EmailCapability = 'EmailCapability',
  BizchatExperimentalCapability = 'BizchatExperimentalCapability',
  Unknown = 'Unknown',
}
