import { Times } from '@copilot-dash/core'
import { TicketErrorDefinition } from './TicketErrorDefinition'
import { TicketErrorSource } from './TicketErrorSource'
import { DashErrorLevel } from '../core/DashErrorLevel'

export const TicketErrorDefinitions = {
  /**
   * No user consent
   */
  NoUserConsentGiven: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No consent given',
    description: 'User Consent was not given for this ticket to collect diagnostic data.',
  },

  /**
   * No Ticket
   */
  NoTicket: {
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No ticket found',
    description:
      'Please check back later. Log files may take up to 1 hour to become available. Possible reasons: the ticket may have expired, is not yet available, or does not exist.',
  },

  /**
   * No Utterance
   */
  NoUtterance: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No utterance found',
    description: 'No utterance found in this ticket.',
  },

  /**
   * No Response
   */
  NoResponse: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No response found',
    description: 'No response found in this ticket. Please contact the team for further investigation.',
  },

  /**
   * No 3S online
   */
  No3sOnline: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S online data found',
    description: 'No 3S online data found in this ticket. Please contact the team for further investigation.',
  },

  /**
   * No Verbatim
   */
  NoVerbatim: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.User,
    message: 'No verbatim given',
    description: 'User did not provide verbatim for this ticket.',
  },

  /**
   * No Email
   */
  NoEmail: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No email found',
    description: 'No email found for this ticket.',
  },

  /**
   * No Email
   */
  NoEmailDueToTenantNotInScope: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Tenant not in scope of MSIT.',
  },

  /**
   * No Conversation
   */
  NoConversations: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No conversation found',
    description: 'This ticket does not have conversation history.',
  },

  /**
   * No USERP
   */
  NoUserpSearch: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No query found',
    description: 'This ticket does not have USERP search query.',
  },

  NoUserpSearchQueryForNotApplicable: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'USERP search query is not applicable for this ticket.',
  },

  NoUserpSearchDataForNotApplicable: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'Not applicable',
    description: 'USERP search data is not applicable for this ticket.',
  },

  NoUserpConversationForNotApplicable: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'Not applicable',
    description: 'Conversation is not applicable for this ticket.',
  },

  NoUserpUtteranceForNotApplicable: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Utterance is not applicable for this ticket.',
  },

  NoUserpResponseForNotApplicable: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Response is not applicable for this ticket.',
  },

  NoUserp3sDebugDataDueToNotSDFV2: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'Not applicable',
    description: '3s Response Data debug is only available for tickets from SDFV2 ring.',
  },

  NoUserp3sDebugData: {
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S debug data found',
    description: 'This ticket does not have 3S debug data.',
  },

  /**
   * No Turn/Interaction
   */
  NoConversationDueToMessageIdNotFound: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No conversation found',
    description: 'No conversation found for the given message ID.',
  },

  /**
   * No Kusto
   */
  NoKusto: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No Kusto data found',
    description: 'No Kusto data found for this ticket.',
  },

  /**
   * No Kusto
   */
  NoKustoDueToExpired: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No Kusto data found',
    description: 'Data in Kusto has expired (30 days retention for now).',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SData: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S diagnostic data found',
    description: 'This ticket does not have 3S diagnostic data',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SDataDueToAifTicket: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: '3S diagnostic data is currently not collected for Admin-Initiated feedback',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SDataDueToExpired: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: '3S diagnostic data has expired (28 days retention for now)',
  },
  /**
   * No Extensibility Log
   */
  NoExtensibilityLog: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No extensibility log',
    description: 'This ticket does not have extensibility log',
  },

  /**
   * No 3S offline data
   */
  No3sOffline: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S offline data found',
    description: 'Failed to fetch the 3S offline data due to some service/pipeline issue.',
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueToNotLatestTurn: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S offline data found',
    description: '3S Offline data is only available for the latest turn of the conversation.',
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueTo6Hours: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: (extra) => {
      const remainingRelativeTime = Times.remainingRelativeTime(extra.ticketCreationTime, 6)
      if (remainingRelativeTime) {
        return `3S diagnostic data is not available yet. It could take up to 6h for the offline data to be uploaded, please come back ${remainingRelativeTime} later.`
      }

      return '3S diagnostic data is not available yet. It could take up to 6h for the offline data to be uploaded, please come back later.'
    },
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueTo48Hours: {
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S offline data found',
    description: (extra) => {
      const remainingRelativeTime = Times.remainingRelativeTime(extra.ticketCreationTime, 48)
      if (remainingRelativeTime) {
        return `Service is retrying to fetch the offline data. Please come back ${remainingRelativeTime} later.`
      }

      return 'Something went wrong for fetching the offline data, service is retrying. Please come back later.'
    },
  },

  /**
   * No 3S data
   */
  No3S: {
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No substrate search data found',
    description: 'Failed to fetch the substrate search data due to some service/pipeline issue.',
  },

  /**
   * No 3S data
   */
  No3SDueToNotTriggered: {
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No need to call 3S',
  },

  /**
   * Confidential Data contained
   */
  ConfidentialDataContained: {
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.ByDesign,
    message: 'Feedback Data Redacted',
    description:
      'Feedback data contains confidential information and cannot be displayed. If you have any questions, please contact the feedback team by submitting a feedback request.',
  },

  /**
   * DSR Ticket
   */
  DSRTicket: {
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'Ticket deleted prescriptively',
    description: 'This ticket was deleted in accordance with Data Subject Request(DSR).',
  },
} as const satisfies Record<string, TicketErrorDefinition>
