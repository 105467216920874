import { Column } from '@copilot-dash/components'
import { INewTicketData } from '@copilot-dash/domain'
import { memo, useMemo } from 'react'
import { CollapsibleTable } from './CollapsibleTable'
import { groupTicketsByTeamV2 } from './utils'

interface IGroupedTicketsByTeamProps {
  readonly tickets: INewTicketData[]
  readonly hasMore?: boolean
  readonly loadMore?: (offset: number) => void
  readonly rowHoverStickySlot?: (rowData: INewTicketData) => JSX.Element
}

export const GroupedTicketsByTeam = memo(function GroupedTicketsByTeam(props: IGroupedTicketsByTeamProps) {
  const teamsSnapshot = app.store.use.getTeamsMap()
  const { tickets } = props

  const nestedArray = useMemo(() => {
    if (teamsSnapshot.status === 'done') {
      return Object.entries(groupTicketsByTeamV2(tickets))
        .filter(([_, tickets]) => {
          return tickets.length > 0
        })
        .map(([teamId, tickets]) => {
          const teamName = teamsSnapshot.data[teamId]?.name
          return [teamName, tickets] as const
        })
        .filter((item): item is [string, INewTicketData[]] => item[0] !== undefined)
        .sort((a, b) => b[1].length - a[1].length)
    }
    return []
  }, [tickets, teamsSnapshot])

  return (
    <Column style={{ width: '100%' }}>
      {nestedArray.map(([teamName, tickets], index) => (
        <CollapsibleTable
          key={teamName}
          tickets={tickets}
          title={teamName}
          defaultExpanded={index === 0}
          hasMore={props.hasMore}
          loadMore={props.loadMore}
          rowHoverStickySlot={props.rowHoverStickySlot}
        />
      ))}
    </Column>
  )
})
