import { ApiUpdateRootCauseResponse } from '@copilot-dash/api'
import { AsyncSnapshot } from '@copilot-dash/core'
import {
  INewTicketData,
  IRootCausesByTeam,
  ITicketRootCauseItem,
  ITopIssueList,
  ITopIssuesBatchInfoList,
  IUpdateRootCause,
  ProductName,
  IBatchTicketCustomTagsType,
  IQuery,
  ProductIds,
  TeamId,
  RootCauseId,
  IRootCauseRecommendation,
} from '@copilot-dash/domain'
import { ISearchTicketOptions } from '@copilot-dash/store'
import { ITeamViewRouteArgs } from '../../../router'

type IToggle = (open: ((pre: boolean) => boolean) | boolean) => void
export interface ITeamViewStore {
  productName: ProductName
  route: ITeamViewRouteStore
  teams: ITeamViewTeams
  rootCauses: ITeamViewRootCauses
  currentQuery?: IQuery
  tickets: ITeamViewTickets
  batchTicketsSubStore: IBatchTicketsStore
  topIssues: ITopIssueStore
  leftDrawerOpen: boolean
  openedRightDrawer: 'filterPanel' | 'ticketDetail' | 'columnSetting' | 'batchUpdatePanel' | null
  selectedTicket: INewTicketData | null | undefined
  computed: {
    filterPanelOpen: boolean
    ticketDetailOpen: boolean
    columnSettingOpen: boolean
    batchUpdatePanelOpen: boolean
    productId: ProductIds | undefined
  }
  orderBy: 'Positive' | 'Reverse' | undefined
  setOrderBy: (type: 'Positive' | 'Reverse' | undefined) => void
  toggleLeftDrawer: IToggle
  toggleFilterPanel: IToggle
  toggleTicketDetail: IToggle
  toggleColumnSetting: IToggle
  toggleBatchUpdatePanel: IToggle
  setOpenedRightDrawer: (drawer: ITeamViewStore['openedRightDrawer']) => void
  setSelectedTicket: (ticket: ITeamViewStore['selectedTicket']) => void
  setCurrentQuery: (query?: IQuery) => void
}

export interface ITeamViewTeams {
  lastSelectedTeamName: string | undefined
  lastSelectedTeamId: TeamId | undefined
  selectedSubMenu: string | undefined
  setProduct: (product: ProductName) => void
  setLastSelectedTeam: (teamName: string, teamId: TeamId, sync2Storage?: boolean, productId?: ProductIds) => void
  setSelectedSubMenu: (category: string | undefined) => void
  syncLastSelectedTeam2Storage: (productId: ProductIds) => void
  syncLastSelectedTeamFromStorage: (productId: ProductIds) => void
}

export interface ITeamViewRootCauses {
  teamRootCauses: AsyncSnapshot<IRootCausesByTeam>
  selectedRootCauseId: string | null
  selectedVsoAccount: string | null
  refreshRootCauseCounter: number
  computed: {
    readonly selectedRootCause: IRootCausesByTeam['rootCauseList'][0] | undefined
  }
  isOpenDiscussionPanel: boolean
  isOpenActivityDiscussionPanel: boolean
  activeDrawer: RootCauseScreenDrawerType | undefined
  setSelectedRootCause: (rootCauseId?: string | null) => void
  fetchTeamRootCauses: (teamName: string, forceRefresh?: boolean) => void
  updateTeamRootCause: (data: IUpdateRootCause) => Promise<ApiUpdateRootCauseResponse>
  updateRootCauseCounter: () => void
  handleDiscussionPanelButtonClick(): void
  handleActivityHistoryPanelButtonClick(): void
  openDrawer(drawer: RootCauseScreenDrawerType): void
  closeDrawer(): void
}

export type NewSearchCopilotTicketsResult = {
  readonly tickets: Array<INewTicketData>
  readonly hasMore: boolean
  readonly ticketCount: number
}

export type SearchTicketIdsResult = {
  readonly ticketIds: string[]
  readonly hasMore: boolean
  readonly ticketCount: number
}

type RecommendationID = string
type IssueID = string

export interface ITeamViewTickets {
  ticketIdListByAll: AsyncSnapshot<SearchTicketIdsResult>
  unRootCausedTicketIds: Record<RecommendationID, AsyncSnapshot<SearchTicketIdsResult>>
  ticketIdListByRootCause: AsyncSnapshot<SearchTicketIdsResult>
  recommendTicketIdListByRootCause: AsyncSnapshot<SearchTicketIdsResult>
  hasMoreTicketsForAll: boolean
  hasMoreLinkedTicketsForRootCause: boolean
  hasMoreRecommendTicketsForRootCause: boolean
  ticketUpdatedByAcceptSuggestionCounter: number
  filterForm: Partial<ISearchTicketOptions>
  topIssueTicketIds: Record<IssueID, AsyncSnapshot<SearchTicketIdsResult>>
  rcrIdList: AsyncSnapshot<Array<RootCauseId>>

  computed: {
    readonly unRootCauseTicketsCount: number | null
  }

  fetchTicketListForAll: (filter?: Partial<ISearchTicketOptions>) => Promise<NewSearchCopilotTicketsResult | undefined>
  filterTicketListInAll: (filter: Partial<ISearchTicketOptions>) => Promise<NewSearchCopilotTicketsResult | undefined>
  fetchTicketListByRootCause: (
    rootCauseId: string,
    filter?: Partial<ISearchTicketOptions>,
  ) => Promise<NewSearchCopilotTicketsResult | undefined>
  filterTicketListInRootCause: (
    filter: Partial<ISearchTicketOptions>,
  ) => Promise<NewSearchCopilotTicketsResult | undefined>
  loadMoreTicketListForAll: (offset: number, count?: number) => void
  loadMoreTicketListByRootCause: (offset: number, count?: number) => void
  resetFilterForm: () => void
  setFilterForm: (filter: Partial<ISearchTicketOptions>) => void

  filterUnRootCausedTickets: (
    recommendationId: RecommendationID,
    filter?: Partial<ISearchTicketOptions>,
  ) => Promise<void>
  loadMoreUnRootCausedTickets: (recommendationId: RecommendationID, offset: number) => Promise<void>
  clearUnRootCausedTickets: (recommendationId: RecommendationID) => void

  fetchTopIssueTickets: (
    issueId: string,
    teamId: TeamId,
    batchId: string,
    filter: Partial<ISearchTicketOptions>,
  ) => Promise<void>
  loadMoreTopIssueTickets: (issueId: string, teamId: TeamId, batchId: string, offset: number) => Promise<void>
  clearTopIssueTickets: (key: string) => void

  fetchRecommendTicketListByRootCause: (
    rootCauseId: string,
    filter?: Partial<ISearchTicketOptions>,
  ) => Promise<NewSearchCopilotTicketsResult | undefined>
  loadMoreRecommendTicketListByRootCause: (offset: number, count?: number) => void
  fetchRCRList: (filter?: Partial<ISearchTicketOptions>) => Promise<void>
}

export interface IBatchTicketsStore {
  tickets: INewTicketData[]
  suggestion: ITicketRootCauseItem | undefined | null
  suggestionV2: IRootCauseRecommendation | undefined | null
  setTickets: (tickets: INewTicketData[]) => void
  setSuggestion: (suggestion: ITicketRootCauseItem | undefined | null) => void
  setSuggestionV2: (suggestion: IRootCauseRecommendation | undefined | null) => void
  batchTicketCustomTagsSnapshot: AsyncSnapshot<IBatchTicketCustomTagsType>
  fetchBatchTicketCustomTags: (ticketIds: string[]) => Promise<IBatchTicketCustomTagsType>
}

export interface ITopIssueStore {
  issueClusteringBatchList: AsyncSnapshot<ITopIssuesBatchInfoList>
  topIssueList: AsyncSnapshot<ITopIssueList>

  fetchIssueClusteringBatchList: (productId: ProductIds) => Promise<ITopIssuesBatchInfoList>
  fetchTopIssueList: (batchId: string, teamId: TeamId) => Promise<ITopIssueList>
  clearTopIssueList: () => void
}

export interface ITeamViewRouteStore {
  args: ITeamViewRouteArgs
  setArgs: (args: ITeamViewRouteArgs) => void
}

export enum RootCauseScreenDrawerType {
  RootCauseDetail = 'RootCauseDetail',
  DiscussionPanel = 'DiscussionPanel',
  ActivityHistory = 'ActivityHistory',
}
