import { ApplicationType, ProductApplication, ProductIds } from '@copilot-dash/domain'
import { useProductIdEndpoints } from '@copilot-dash/store'
import { compact, includes, sortBy, uniq } from 'lodash'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  productId: ProductIds
  platforms: string[]
  licenses: string[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const ApplicationFilter: React.FC<IProps> = ({
  productId,
  platforms,
  licenses,
  selectedValues,
  onChangeValue,
}) => {
  const allProductEndpoints = useProductIdEndpoints({ productId }).data
  const allApplications = uniq(compact(allProductEndpoints?.map((endpoint) => endpoint.application)))

  let productEndpoints =
    platforms.length === 0
      ? allProductEndpoints
      : allProductEndpoints?.filter((endpoint) => includes(platforms, endpoint.platform))

  productEndpoints =
    licenses.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(licenses, endpoint.license))

  const enabledItems = uniq(compact(productEndpoints?.map((endpoint) => endpoint.application)))

  const applicationList = sortBy(allApplications, (item) => sortApplication(item)).map((application) => ({
    key: application,
    text: application,
    disabled: !enabledItems.includes(application),
  }))

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-applicationFilter'}
        filterType={getTagDisplayName('applications')}
        isGroup={true}
        optionsList={applicationList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}

function sortApplication(item: ApplicationType): number {
  return ProductApplication.indexOf(item)
}
