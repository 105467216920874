import { TeamId } from '@copilot-dash/domain'
import { Spinner } from '@fluentui/react-components'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'
import { useTicketFilterStore } from '../../store/store'

interface IProps {
  selectedValues: TeamId[] | undefined
  onChangeValue: (value: TeamId[]) => void
}

export const TeamIdsFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const teamListSnapshot = app.store.use.getTeams(selectedProductId)

  const filterOptions = useMemo(() => {
    if (teamListSnapshot.status !== 'done') {
      return []
    }
    return teamListSnapshot.data
      .filter((team) => team.id && team.name)
      .map((team) => ({
        key: team.id.toString(),
        text: team.name.toString(),
      }))
  }, [teamListSnapshot])

  return teamListSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-teamIdFilter'}
      filterType={getTagDisplayName('teamIds')}
      isGroup={false}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      showInput={true}
      infoLabelContent={
        <>
          Team applies only to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
