import { generateObjectValueText } from './generateObjectValueText'
import { AnyTableObjectValue } from '../IState'

export function generateObjectValue(key: string, value: unknown): AnyTableObjectValue {
  return {
    key,
    value,
    valueText: generateObjectValueText(value),
  }
}
