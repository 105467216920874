import { TicketTriggeredSkillList } from '@copilot-dash/domain'
import { memo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const TriggeredSkillFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptions: IFilterOption[] = TicketTriggeredSkillList.map((item) => ({
    key: item.key,
    text: item.text,
  }))

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-triggeredSkillFilter'}
      filterType={getTagDisplayName('triggeredSkill')}
      isGroup={false}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        onChangeValue(item)
      }}
    />
  )
})

TriggeredSkillFilter.displayName = 'TriggeredSkillFilter'
