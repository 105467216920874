import { Column, Spacer } from '@copilot-dash/components'
import {
  Checkbox,
  InfoLabel,
  OptionGroup,
  Text,
  makeStyles,
  mergeClasses,
  tokens,
  useId,
} from '@fluentui/react-components'
import type { InfoLabelProps } from '@fluentui/react-components'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CopilotDashPopover } from './CopilotDashPopover'
import { IFilterOption } from './IFilterOption.types'

interface IProps {
  comboId: string
  filterType: string
  placeholder?: string
  infoLabelContent?: React.HTMLAttributes<HTMLElement> & InfoLabelProps['info']
  isGroup: boolean
  optionsList: IFilterOption[]
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[]) => void
  showInput?: boolean
  disabled?: boolean
  className?: string
}

export const CopilotDashMultiSelectDropdown: React.FC<IProps> = ({
  comboId,
  filterType,
  placeholder = 'Select',
  infoLabelContent,
  isGroup,
  optionsList,
  defaultSelectedOption,
  onChangeFilter,
  showInput,
  disabled,
  className,
}) => {
  const styles = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [displayValue, setDisplayValue] = useState<string>('')
  const [inputValue, setInputValue] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const optionsListWithAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [{ key: 'all', text: 'Select All' }].concat(optionsList)
    }
    return optionsList
  }, [optionsList])

  const allItems = useMemo(() => {
    return Object.values(optionsList)
      .flat()
      .map((item: IFilterOption) => item.key)
  }, [optionsList])

  const allItemsWithSelectAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [...allItems, 'all']
    }
    return allItems
  }, [optionsList, allItems])

  const groups = optionsList.reduce((acc: { [key: string]: IFilterOption[] }, item: IFilterOption) => {
    const menu = item.menu || ''
    ;(acc[menu] = acc[menu] || []).push(item)
    return acc
  }, {})

  useEffect(() => {
    setSelectedOptions(
      defaultSelectedOption
        ? defaultSelectedOption.length === optionsList.length
          ? [...defaultSelectedOption, 'all']
          : defaultSelectedOption
        : [],
    )
  }, [defaultSelectedOption, optionsList])

  useEffect(() => {
    setDisplayValue(
      selectedOptions.length === allItemsWithSelectAll.length
        ? 'All selected'
        : selectedOptions.length > 0
          ? selectedOptions
              .map((item) => {
                return optionsList.find((option) => option.key === item)?.text ?? null
              })
              .join('; ')
          : '',
    )
  }, [selectedOptions, allItemsWithSelectAll.length, optionsList])

  const handleCheckboxChange = (checked: string | boolean, option: string) => {
    if (checked) {
      if (option === 'all' || [...selectedOptions, option].length === allItems.length) {
        setSelectedOptions(allItemsWithSelectAll)
        onChangeFilter(allItems)
      } else {
        setSelectedOptions([...selectedOptions, option])
        onChangeFilter([...selectedOptions, option])
      }
    } else {
      if (option === 'all') {
        setSelectedOptions([])
        onChangeFilter([])
      } else {
        setSelectedOptions(selectedOptions.filter((item) => item !== 'all' && item !== option))
        onChangeFilter(selectedOptions.filter((item) => item !== 'all' && item !== option))
      }
    }
  }
  const handleCheckBoxChecked = useCallback(
    (key: string) => {
      return selectedOptions.includes(key)
    },
    [selectedOptions],
  )

  const optionRender = (item: IFilterOption) => {
    return (
      <Column key={item.key} title={item.text}>
        <label htmlFor={`checkBox-${filterType}-${item.key}`} className={styles.checkboxLabel}>
          <Checkbox
            checked={handleCheckBoxChecked(item.key)}
            label={item.text}
            onChange={(_, data) => handleCheckboxChange(data.checked, item.key)}
            id={`checkBox-${filterType}-${item.key}`}
            disabled={item.disabled}
          />
        </label>
      </Column>
    )
  }

  function groupFilterOptions(options: [string, IFilterOption[]][]) {
    const filteredOptions: { [key: string]: IFilterOption[] } = {}
    options.forEach(([group, items]: [string, IFilterOption[]]) => {
      const includesOption = items.filter((item) =>
        item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
      )
      if (includesOption.length > 0) {
        filteredOptions[group] = includesOption
      }
    })

    return Object.keys(filteredOptions).length > 0 ? (
      <Column>
        <label htmlFor={`checkBox-${filterType}-all`} className={styles.checkboxLabel}>
          <Checkbox
            checked={handleCheckBoxChecked('all')}
            key="all"
            label="Select All"
            onChange={(_, data) => handleCheckboxChange(data.checked, 'all')}
            id={`checkBox-${filterType}-all`}
            disabled={optionsList.some((option) => option.disabled)}
          />
        </label>
        {Object.entries(filteredOptions).map(([group, items]: [string, IFilterOption[]]) =>
          group ? (
            <OptionGroup key={group} label={group} className={styles.optionGroup}>
              {items.map((item) => optionRender(item))}
            </OptionGroup>
          ) : (
            items.map((item) => optionRender(item))
          ),
        )}
      </Column>
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  function filterOptions(optionsListWithAll: IFilterOption[]) {
    const filteredOptions = optionsListWithAll.filter((item) => {
      return item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      <Column>{filteredOptions.map((item) => optionRender(item))}</Column>
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = isGroup ? groupFilterOptions(Object.entries(groups)) : filterOptions(optionsListWithAll)

  const handleSelectCleanClick = () => {
    setSelectedOptions([])
    onChangeFilter([])
  }

  return (
    <Column className={mergeClasses(className)}>
      <InfoLabel id={useId(comboId)} weight="semibold" info={infoLabelContent}>
        {filterType}:
      </InfoLabel>
      <Spacer size={4} />
      <CopilotDashPopover
        optionContent={targetFinalOptions}
        value={displayValue}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        handleSelectCleanClick={handleSelectCleanClick}
        inputValue={inputValue}
        setInputValue={setInputValue}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        showInput={showInput}
        disabled={disabled}
      />
    </Column>
  )
}

const useStyles = makeStyles({
  optionGroup: {
    wordBreak: 'break-word',
  },
  checkboxLabel: {
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
