import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { OptionSetPriority } from '../../config/OptionSetPriority'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
  disabled?: boolean
}

export const PriorityFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, disabled }) => {
  const optionsList: IFilterOption[] = OptionSetPriority

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-priorityFilter'}
      filterType={getTagDisplayName('priority')}
      isGroup={false}
      disabled={disabled}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => onChangeValue(item)}
      infoLabelContent={
        <>
          Priority is only assigned to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
