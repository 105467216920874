import { createStoreContext, useStore } from '@copilot-dash/core'
import { ColDef } from 'ag-grid-community'
import { ReactNode, useEffect, useMemo } from 'react'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { AnyTableObject, IState } from './store/IState'
import { generateAnyColumn } from './store/utils/generateAnyColumn'
import { generateAnyColumnKeys } from './store/utils/generateAnyColumnKeys'
import { generateObjects } from './store/utils/generateObjects'
import { AdditionalColumnConfig } from '../any-table-additionalColumn/AdditionalColumnConfig'
import { AdditionalColumnType } from '../any-table-additionalColumn/AdditionalColumnType'

interface IProps {
  readonly data: unknown[]
  readonly keyword: string | undefined
  readonly children: ReactNode
  readonly additionalColumns: AdditionalColumnType[] | undefined
}

export function AnyTableStore({ data, children, keyword, additionalColumns }: IProps) {
  const objects = useMemo(() => {
    return generateObjects(data)
  }, [data])

  const columns: ColDef<AnyTableObject, unknown>[] = useMemo(() => {
    const keys = generateAnyColumnKeys(objects)

    const defaultColumn = keys.map((key) => {
      return generateAnyColumn(key, objects)
    })

    if (additionalColumns) {
      const additionalColumnConfig = additionalColumns.map((item) => {
        return { ...AdditionalColumnConfig, headerName: item.title, cellRenderer: item.cellRenderer }
      })
      return [...additionalColumnConfig, ...defaultColumn]
    }
    return defaultColumn
  }, [additionalColumns, objects])

  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({ data, objects, columns, keyword, additionalColumns }),
    actions: createActions({ set, get }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.data = data
      state.objects = objects
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.columns = columns as any
      state.keyword = keyword
      state.additionalColumns = additionalColumns
    })
  }, [store, data, columns, keyword, objects, additionalColumns])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()

AnyTableStore.use = Context.use
AnyTableStore.useActions = Context.useActions
