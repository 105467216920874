import { TicketChatMode } from '@copilot-dash/domain'
import { IFilterOption } from '../../Filter/IFilterOption.types'

export const OptionSetChatMode: IFilterOption[] = [
  {
    key: TicketChatMode.Text,
    text: 'Text',
  },
  {
    key: TicketChatMode.Voice,
    text: 'Voice',
  },
]
