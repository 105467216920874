import { FeedbackId } from '@copilot-dash/domain'
import { AxiosRequestConfig } from 'axios'
import { z, ZodType } from 'zod'
import { CopilotDashApiClient } from './CopilotDashApiClient'
import {
  api3sOfflineDataGroup2Schema,
  api3sOfflineDataGroup3Schema,
  api3sOfflineDataGroup4Schema,
  api3sOnlineDataGroup1Schema,
  api3sOnlineDataGroup2Schema,
  api3sOnlineDataGroup3Schema,
  api3sOnlineDataGroup4Schema,
  ApiActivityHistoryResponseV2,
  apiActivityHistoryResponseV2Schema,
  ApiAISearchRequestBody,
  ApiAISearchRequestPrams,
  ApiAISearchResponse,
  apiAISearchResponseSchema,
  ApiAllCustomTagsResponse,
  apiAllCustomTagsResponseSchema,
  apiAugLoopLogItemSchema,
  apiBizChat3SLatencyLogItemV2Schema,
  apiBizPerfLogItemV2Schema,
  apiConversationGroup1Schema,
  apiConversationGroup2Schema,
  apiConversationGroup3Schema,
  apiConversationGroup4Schema,
  apiExtensibilityLogItemV2Schema,
  apiGwsLogItemV2Schema,
  apiLlmKustoLogItemSchema,
  ApiRootCauseCustomTagsResponse,
  apiRootCauseCustomTagsResponseSchema,
  apiStateAndDurationKustoLogItemV2Schema,
  apiTraceKustoLogItemV2Schema,
  apiUserpGroup1Schema,
  apiUserpGroup2Schema,
  apiUserpGroup3Schema,
  apiUserpGroup4Schema,
  ApiV2BatchUpdateWorkItemCustomTagsRequest,
  ApiV2BatchUpdateWorkItemCustomTagsResponse,
  apiV2BatchUpdateWorkItemCustomTagsResponseSchema,
  ApiV2CreateRootCauseRequest,
  ApiV2CreateRootCauseResponse,
  apiV2CreateRootCauseResponseSchema,
  ApiV2FeatureTeamsResponse,
  apiV2FeatureTeamsResponseSchema,
  ApiV2GetRootCauseByIdResponse,
  apiV2GetRootCauseByIdResponseSchema,
  ApiV2PatchFeatureTeamRequest,
  ApiV2PatchWorkItemsRequest,
  ApiV2PatchWorkItemsResponse,
  apiV2PatchWorkItemsResponseSchema,
  ApiV2PeopleAssignmentsResponse,
  apiV2PeopleAssignmentsResponseSchema,
  ApiV2ProductsResponse,
  apiV2ProductsResponseSchema,
  ApiV2RootCauseRecommendationsResponse,
  apiV2RootCauseRecommendationsSchema,
  ApiV2RootCausesResponse,
  apiV2RootCausesResponseSchema,
  ApiV2SymptomReportResponse,
  apiV2SymptomReportResponseSchema,
  ApiV2Ticket,
  ApiV2TicketCustomTagsResponse,
  apiV2TicketCustomTagsResponseSchema,
  apiV2TicketSchema,
  ApiV2UpdateRootCauseRequest,
  ApiV2WorkItemResponse,
  apiV2WorkItemResponseSchema,
  apiGetQueryResponseSchema,
  ApiGetQueryResponse,
  apiDeleteQueryResponseSchema,
  ApiDeleteQueryResponse,
  ApiUpdateQueryRequest,
  ApiCreateQueryRequest,
} from './types'
import {
  ApiBatchRootCausesCustomTagsResponse,
  apiBatchRootCausesCustomTagsResponseSchema,
} from './types/ApiBatchTicketsCustomTagsResponse'
import { ApiTenantListResponse, apiTenantListResponseSchema } from './types/ApiTenantListResponse'
import {
  ApiAttachmentRequest,
  ApiAttachmentResponse,
  ApiAttachmentResponseSchema,
  ApiAttachmentUrlResponse,
  arrayBufferSchema,
} from './types/ApiV2Attachment'
import { IBatchUpdateRootCauseRecommendationsRequest } from './types/ApiV2BatchUpdateRootCauseRecommendaitonsRequest'
import {
  ApiV2batchUpdateRootCauseRecommendationResponse,
  apiV2batchUpdateRootCauseRecommendationResponseSchema,
} from './types/ApiV2BatchUpdateRootCauseRecommendationResponse'
import { ApiV2DeleteRootCauseResponse, ApiV2DeleteRootCauseResponseSchema } from './types/ApiV2DeleteRootCauseResponse'
import { ApiV2ProductListResponse, apiV2ProductListResponseSchema } from './types/ApiV2ProductListResponse'
import { ApiTeamItemV2, apiTeamListResponseV2Schema, ApiV2TeamListResponse } from './types/ApiV2TeamListResponse'
import { ApiUpsertCommentRequestV2 } from './types/ApiV2UpsertCommentRequest'
import {
  ApiCommentItemV2,
  apiCommentItemV2Schema,
  apiCommentListResponseV2Schema,
  ApiCommentsResponseV2,
} from './types/comments/ApiV2CommentsResponse'
import {
  ApiDeleteCommentResponseV2,
  apiDeleteCommentResponseV2Schema,
} from './types/comments/ApiV2DeleteCommentResponse'
import {
  ApiAISearchRootCauseRequestBody,
  ApiAISearchRootCauseRequestParams,
} from './types/rootcause/ApiAISearchRootcauseRequest'
import {
  ApiAISearchRootcauseResponse,
  apiAISearchRootCauseResponseSchema,
} from './types/rootcause/ApiAISearchRootcauseResponse'
import { IApiSevalQuerySetRequest } from './types/seval/ApiSevalQuerySetRequest'
import { ApiSevalQuerySetResponseSchema, IApiSevalQuerySetResponse } from './types/seval/ApiSevalQuerySetResponse'
import { api3sOfflineDataGroup1Schema } from './types/ticket-source-3s-offline/Api3sOfflineDataGroup1.schema'
import { apiConversationSchema } from './types/ticket-source-conversation/ApiConversation.schema'
import { apiInteractionInfosSchema } from './types/ticket-source-interaction/ApiTicketInteractions.schema'
import { ApiClient } from '../../client/ApiClient'
import { Caches } from '../../client/ApiClientCaches'
import { DashApiOptions } from '../../DashApiOptions'

/**
 * Code:
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 *
 * Swagger:
 * https://copilotdashwebservice-service.namcentral240175.copilot-dash-webservice.centralus-test.cosmic-int.office.net/swagger/index.html
 */
export class CopilotDashApi {
  private readonly client: ApiClient

  constructor(options: DashApiOptions) {
    this.client = new CopilotDashApiClient(options)
  }

  getTicket(ticketId: string): Promise<ApiV2Ticket> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}`, {
      schema: apiV2TicketSchema,
      cache: Caches.SHORT,
    })
  }

  getTicketSymptomReport(ticketId: string): Promise<ApiV2SymptomReportResponse> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}/SymptomReports`, {
      schema: apiV2SymptomReportResponseSchema,
    })
  }

  getAISearchTicket(query: ApiAISearchRequestPrams, data: ApiAISearchRequestBody): Promise<ApiAISearchResponse> {
    return this.client.post(`/api/v2/tickets/search`, {
      params: query,
      data: data,
      schema: apiAISearchResponseSchema,
    })
  }

  getTicketBlobFile(
    ticketId: string,
    blobUrl: string,
    options?: Pick<AxiosRequestConfig, 'onDownloadProgress'>,
  ): Promise<Blob> {
    return this.client.get(`/api/v2/tickets/${encodeURIComponent(ticketId)}/blobFile`, {
      params: {
        blobUrl: blobUrl,
      },
      responseType: 'blob',
      schema: z.any(),
      onDownloadProgress: options?.onDownloadProgress,
    })
  }

  getTicketBlobRaw(ticketId: string, blobUrl: string): Promise<unknown> {
    return this.client.get(`/api/v2/tickets/${encodeURIComponent(ticketId)}/blobFile`, {
      params: {
        blobUrl: blobUrl,
      },
      schema: z.unknown(),
      cache: Caches.SHORT,
    })
  }

  getTicketBlob(ticketId: string, blobUrl: string) {
    const get = async <T>(schema: ZodType<T>): Promise<T> => {
      const encodedTicketId = encodeURIComponent(ticketId)
      return this.client.get(`/api/v2/tickets/${encodedTicketId}/blobFile`, {
        params: {
          blobUrl: blobUrl,
        },
        schema: schema,
      })
    }

    return {
      // Conversation
      asConversation: () => get(apiConversationSchema),
      asConversationGroup1: () => get(apiConversationGroup1Schema),
      asConversationGroup2: () => get(apiConversationGroup2Schema),
      asConversationGroup3: () => get(apiConversationGroup3Schema),
      asConversationGroup4: () => get(apiConversationGroup4Schema),

      // Search online
      asSubstrateSearchOnlineGroup1: () => get(api3sOnlineDataGroup1Schema),
      asSubstrateSearchOnlineGroup2: () => get(api3sOnlineDataGroup2Schema),
      asSubstrateSearchOnlineGroup3: () => get(api3sOnlineDataGroup3Schema),
      asSubstrateSearchOnlineGroup4: () => get(api3sOnlineDataGroup4Schema),

      // Search offline
      asSubstrateSearchOfflineGroup1: () => get(api3sOfflineDataGroup1Schema),
      asSubstrateSearchOfflineGroup2: () => get(api3sOfflineDataGroup2Schema),
      asSubstrateSearchOfflineGroup3: () => get(api3sOfflineDataGroup3Schema),
      asSubstrateSearchOfflineGroup4: () => get(api3sOfflineDataGroup4Schema),

      // Userp
      asUserpGroup1: () => get(apiUserpGroup1Schema),
      asUserpGroup2: () => get(apiUserpGroup2Schema),
      asUserpGroup3: () => get(apiUserpGroup3Schema),
      asUserpGroup4: () => get(apiUserpGroup4Schema),

      // Kusto
      asSubstrateSearchLog: () => get(z.array(apiGwsLogItemV2Schema)),
      asSubstrateSearchLatencyLog: () => get(z.array(apiBizChat3SLatencyLogItemV2Schema)),
      asPerformanceLog: () => get(z.array(apiBizPerfLogItemV2Schema)),
      asLlmLog: () => get(z.array(apiLlmKustoLogItemSchema)),
      asAugLoopLog: () => get(z.array(apiAugLoopLogItemSchema)),
      asStateDurationLog: () => get(z.array(apiStateAndDurationKustoLogItemV2Schema)),
      asTraceLog: () => get(z.array(apiTraceKustoLogItemV2Schema)),
      asExtensibility: () => get(z.array(apiExtensibilityLogItemV2Schema)),

      // Other
      asInteractions: () => get(z.array(apiInteractionInfosSchema)),
    }
  }

  //Due to the current API only support Image/* types. that's why we named it.
  uploadImageV2(data: ApiAttachmentRequest): Promise<ApiAttachmentResponse> {
    const formData = new FormData()
    formData.append('file', data.file)
    return this.client.put(`/api/v2/discussions/attachments`, {
      data: formData,
      schema: ApiAttachmentResponseSchema,
      headers: {
        'Content-Type': data.file.type || 'application/octet-stream',
      },
    })
  }

  getAttachmentV2(rawUrl: string): Promise<ApiAttachmentUrlResponse> {
    return this.client.get('/api/v2/discussions/attachments', {
      params: { url: rawUrl },
      schema: arrayBufferSchema,
      cache: Caches.SHORT,
      responseType: 'arraybuffer',
    })
  }

  //rootcause attachment
  uploadRootCauseDescriptionImage(data: ApiAttachmentRequest): Promise<ApiAttachmentResponse> {
    const formData = new FormData()
    formData.append('file', data.file)
    return this.client.put(`/api/v2/teamView/rootCauses/descriptionAttachment`, {
      data: formData,
      schema: ApiAttachmentResponseSchema,
      headers: {
        'Content-Type': data.file.type || 'application/octet-stream',
      },
    })
  }
  getRootCauseDescriptionAttachment(rawUrl: string): Promise<ApiAttachmentUrlResponse> {
    return this.client.get('/api/v2/teamView/rootCauses/descriptionAttachment', {
      params: { url: rawUrl },
      schema: arrayBufferSchema,
      cache: Caches.SHORT,
      responseType: 'arraybuffer',
    })
  }

  createComment(discussionId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.put(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentItemV2Schema,
      data: data,
    })
  }

  deleteComment(discussionId: string, commentId: string): Promise<ApiDeleteCommentResponseV2> {
    return this.client.delete(
      `/api/v2/discussions/${encodeURIComponent(discussionId)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiDeleteCommentResponseV2Schema,
      },
    )
  }

  editComment(id: string, commentId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.patch(
      `/api/v2/discussions/${encodeURIComponent(id)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiCommentItemV2Schema,
        data: data,
      },
    )
  }

  getComments(discussionId: string): Promise<ApiCommentsResponseV2> {
    return this.client.get(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentListResponseV2Schema,
    })
  }

  // Teams APIs
  getProductList(): Promise<ApiV2ProductListResponse> {
    return this.client.get('/api/v2/configuration/productList', {
      schema: apiV2ProductListResponseSchema,
    })
  }
  getTeamList(productId?: string): Promise<ApiV2TeamListResponse> {
    return this.client.get('/api/v2/teamView/featureTeams', {
      params: {
        productId: productId,
      },
      schema: apiTeamListResponseV2Schema,
    })
  }
  getTeamListFlatten(productId?: string): Promise<Array<Exclude<ApiTeamItemV2, 'subTeamList'>>> {
    return this.getTeamList(productId).then((teams) => {
      const flattenTeams = new Array<Exclude<ApiTeamItemV2, 'subTeamList'>>()
      const flatten = (team: ApiTeamItemV2) => {
        team.isAssignable && flattenTeams.push(team)
        team.subTeamList.forEach(flatten)
      }
      teams.forEach(flatten)
      return flattenTeams
    })
  }
  getProducts(): Promise<ApiV2ProductsResponse> {
    return this.client.get(`/api/v2/configuration/products`, {
      schema: apiV2ProductsResponseSchema,
    })
  }
  getFeatureTeams(teamId: string): Promise<ApiV2FeatureTeamsResponse> {
    return this.client.get(`/api/v2/teamView/featureTeams/${teamId}`, {
      schema: apiV2FeatureTeamsResponseSchema,
    })
  }
  patchFeatureTeams(teamId: string, data: ApiV2PatchFeatureTeamRequest): Promise<ApiV2FeatureTeamsResponse> {
    return this.client.patch(`/api/v2/teamView/featureTeams/${encodeURIComponent(teamId)}`, {
      data: data,
      schema: apiV2FeatureTeamsResponseSchema,
    })
  }
  getPeopleAssignments(teamId: string): Promise<ApiV2PeopleAssignmentsResponse> {
    return this.client.get(`/api/v2/teamView/workItems/peopleAssignments/find`, {
      params: { teamId: teamId },
      schema: apiV2PeopleAssignmentsResponseSchema,
    })
  }

  getAISearchRootCauses(
    query: ApiAISearchRootCauseRequestParams,
    data: ApiAISearchRootCauseRequestBody,
  ): Promise<ApiAISearchRootcauseResponse> {
    return this.client.post(`/api/v2/rootCauses/search`, {
      params: query,
      data: data,
      schema: apiAISearchRootCauseResponseSchema,
    })
  }
  getRootCauses(teamId: string): Promise<ApiV2RootCausesResponse> {
    return this.client.get(`/api/v2/teamView/rootCauses/find`, {
      params: { teamId: teamId },
      schema: apiV2RootCausesResponseSchema,
    })
  }
  createRootCause(data: ApiV2CreateRootCauseRequest): Promise<ApiV2CreateRootCauseResponse> {
    return this.client.put(`/api/v2/teamView/rootCause`, {
      data: data,
      schema: apiV2CreateRootCauseResponseSchema,
    })
  }
  updateRootCause(rootCauseId: string, data: ApiV2UpdateRootCauseRequest) {
    return this.client.patch(`/api/v2/teamView/rootCauses/${rootCauseId}`, {
      data: data,
      schema: apiV2CreateRootCauseResponseSchema,
    })
  }
  getRootCauseById(rootCauseId: string): Promise<ApiV2GetRootCauseByIdResponse> {
    return this.client.get(`/api/v2/teamView/rootCauses/${rootCauseId}`, {
      schema: apiV2GetRootCauseByIdResponseSchema,
    })
  }
  deleteRootCauseById(rootCauseId: string): Promise<ApiV2DeleteRootCauseResponse> {
    return this.client.delete(`/api/v2/teamView/rootCauses/${rootCauseId}`, {
      schema: ApiV2DeleteRootCauseResponseSchema,
    })
  }
  getWorkItemByFeedbackId(feedbackId: FeedbackId, messageId?: string): Promise<ApiV2WorkItemResponse> {
    return this.client.get(`/api/v2/teamView/workItems/find`, {
      params: {
        feedbackId: feedbackId,
        messageId: messageId,
      },
      schema: apiV2WorkItemResponseSchema,
    })
  }
  patchWorkItems(data: ApiV2PatchWorkItemsRequest, signal?: AbortSignal): Promise<ApiV2PatchWorkItemsResponse> {
    return this.client.patch(`/api/v2/teamView/workItems/batch`, {
      data: data,
      schema: apiV2PatchWorkItemsResponseSchema,
      signal,
    })
  }
  getRootCauseRecommendations(feedbackId: string): Promise<ApiV2RootCauseRecommendationsResponse> {
    return this.client.get(`api/v2/teamView/rootCauseRecommendations/${feedbackId}`, {
      schema: apiV2RootCauseRecommendationsSchema,
    })
  }

  updateRootCauseRecommendationAction(
    data: IBatchUpdateRootCauseRecommendationsRequest,
  ): Promise<ApiV2batchUpdateRootCauseRecommendationResponse> {
    return this.client.put(`api/v2/teamView/rootCauseRecommendations/batch`, {
      data,
      schema: apiV2batchUpdateRootCauseRecommendationResponseSchema,
    })
  }

  getTenantList(keyWord: string, size: number): Promise<ApiTenantListResponse> {
    return this.client.get('api/v2/configuration/tenants', {
      schema: apiTenantListResponseSchema,
      cache: Caches.LONG,
      params: { keyWord: keyWord, size: size },
    })
  }

  getTenantsByIds(tenantIds: Array<string>): Promise<ApiTenantListResponse> {
    return this.client.post('api/v2/configuration/tenants', {
      schema: apiTenantListResponseSchema,
      cache: Caches.LONG,
      data: tenantIds,
    })
  }

  getAllCustomTags(): Promise<ApiAllCustomTagsResponse> {
    return this.client.get('/api/v2/customTags/all', {
      schema: apiAllCustomTagsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  //WorkItem CustomTags
  getWorkItemCustomTags(ticketId: string): Promise<ApiV2TicketCustomTagsResponse> {
    return this.client.get('api/v2/customTags/workItem/find', {
      params: { feedbackId: ticketId },
      schema: apiV2TicketCustomTagsResponseSchema,
    })
  }

  patchWorkItemCustomTags(
    request: ApiV2BatchUpdateWorkItemCustomTagsRequest,
    signal?: AbortSignal,
  ): Promise<ApiV2BatchUpdateWorkItemCustomTagsResponse> {
    return this.client.patch('api/v2/customTags/workItems', {
      data: request,
      schema: apiV2BatchUpdateWorkItemCustomTagsResponseSchema,
      signal,
    })
  }

  //RootCause CustomTags
  patchRootCauseCustomTags(
    rootCauseGuid: string,
    customTags: Array<string>,
  ): Promise<ApiBatchRootCausesCustomTagsResponse> {
    return this.client.patch('api/v2/customTags/rootCause', {
      data: { rootCauseGuid: rootCauseGuid, customTags: customTags },
      schema: apiBatchRootCausesCustomTagsResponseSchema,
    })
  }

  getRootCauseCustomTags(rootCauseGuid: string): Promise<ApiRootCauseCustomTagsResponse> {
    return this.client.get(`api/v2/CustomTags/RootCause/find`, {
      params: { rootCauseGuid: rootCauseGuid },
      schema: apiRootCauseCustomTagsResponseSchema,
      cache: Caches.NONE,
    })
  }

  // Put Query Set
  putQuerySetToSeval(data: IApiSevalQuerySetRequest): Promise<IApiSevalQuerySetResponse> {
    return this.client.put(`/api/v2/teamView/querySet/attachments/${data.templateId}`, {
      data: { content: JSON.stringify(data) },
      schema: ApiSevalQuerySetResponseSchema,
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    })
  }

  // Activity History
  getActivityHistoryV2(entityId: string): Promise<ApiActivityHistoryResponseV2> {
    return this.client.get(`/api/v2/activityHistory/${encodeURIComponent(entityId)}`, {
      schema: apiActivityHistoryResponseV2Schema,
    })
  }

  // Saved Query
  createQuery(data: ApiCreateQueryRequest): Promise<ApiGetQueryResponse> {
    return this.client.put('/api/v2/savedQuery', {
      data: data,
      schema: apiGetQueryResponseSchema,
    })
  }

  updateQuery(queryId: string, data: ApiUpdateQueryRequest): Promise<ApiGetQueryResponse> {
    return this.client.patch(`/api/v2/savedQuery/${encodeURIComponent(queryId)}`, {
      data: data,
      schema: apiGetQueryResponseSchema,
    })
  }

  getQueryByQueryId(queryId: string): Promise<ApiGetQueryResponse> {
    return this.client.get(`/api/v2/savedQuery/${encodeURIComponent(queryId)}`, {
      schema: apiGetQueryResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getTeamQueries(teamId: number): Promise<ApiGetQueryResponse> {
    return this.client.get(`/api/v2/savedQuery/find`, {
      params: { teamId: teamId },
      schema: apiGetQueryResponseSchema,
    })
  }

  getMyQueries(): Promise<ApiGetQueryResponse> {
    return this.client.get('/api/v2/savedQuery/MyQueries', {
      schema: apiGetQueryResponseSchema,
    })
  }

  deleteQuery(queryId: string): Promise<ApiDeleteQueryResponse> {
    return this.client.delete(`/api/v2/savedQuery/${encodeURIComponent(queryId)}`, {
      schema: apiDeleteQueryResponseSchema,
    })
  }
}
