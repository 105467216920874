import { DashRing, IDashEnvironment } from '@copilot-dash/core'
import { createFeature } from './common/createFeature'
import { IFeature } from './common/IFeature'

export function createFeatures(env: IDashEnvironment) {
  return {
    settingsEntry: create({
      key: 'settings_entry',
      rings: ['dev', 'test', 'sdf'],
      description: 'Show settings entry',
    }),

    mcpExperimentFeedbackBoard: create({
      key: 'EnableMcpExperimentFeedbackBoard',
      rings: ['dev'],
      description: 'MCP Experiment Feedback Board',
    }),

    v2Ticket: create({
      key: 'api_v2_ticket_enabled',
      rings: ['dev', 'test', 'sdf', 'msit'],
      description: 'Enable API V2 for ticket',
      group: 'API V2',
    }),

    v2SymptomReport: create({
      key: 'api_v2_symptom_report_enabled',
      rings: [],
      description: 'Enable API V2 for symptom report',
      group: 'API V2',
    }),

    invocationSlicer: create({
      key: 'EnableInvocationSlicer',
      rings: [],
      description: 'Invocation Slicer',
      group: 'Search',
    }),

    v2Discussion: create({
      key: 'api_v2_ticket_discussion',
      rings: ['dev', 'test', 'sdf', 'msit'],
      description: 'Enable API V2 for discussion panel',
      group: 'API V2',
    }),

    v2TeamView: create({
      key: 'api_v2_team_view',
      rings: ['dev', 'test', 'sdf', 'msit'],
      description: 'Enable API V2 for team view',
      group: 'API V2',
    }),

    v2QuerySet: create({
      key: 'generate_query_set_template',
      rings: ['dev', 'test', 'sdf'],
      description: 'Enable Generate Query Set Template',
      group: 'API V2',
    }),

    finetuneCluster: create({
      key: 'finetune_cluster',
      rings: [],
      description: 'Enable Finetune Cluster',
      group: 'Configurable Cluster',
    }),

    feedbackCopilot: create({
      key: 'feedback_copilot',
      rings: ['dev', 'test', 'sdf'],
      description: 'Enable Feedback Copilot',
      group: 'Feedback Copilot',
    }),

    feedbackCopilotPrompts: create({
      key: 'feedback_copilot_prompts',
      rings: [],
      description: 'Enable Feedback Copilot Prompts',
      group: 'Feedback Copilot',
    }),

    translationButtonVisible: create({
      key: 'translation',
      rings: ['dev', 'test', 'sdf', 'msit'],
      description: 'Show translation button',
    }),

    ticketDiagnostic: create({
      key: 'ticket_diagnostic',
      rings: ['dev', 'test', 'sdf'],
      description: 'Enable ticket diagnostic entry',
      group: 'Developer',
    }),

    torusApp: create({
      key: 'torus_app',
      rings: [],
      description: 'Use Torus App as default app',
      group: 'Developer',
    }),

    feedbackEntry: create({
      key: 'feedback_entry',
      rings: ['dev', 'test', 'sdf'],
      description: 'Show feedback entry options',
      group: 'Developer',
    }),

    cohortFilter: create({
      key: 'cohort_filter',
      rings: ['dev', 'test', 'sdf'],
      description: 'Show Usage Intensity Cohort and Engagement Cohort',
      group: 'Developer',
    }),
  } satisfies Record<string, IFeature>

  function create(params: {
    key: string //
    rings: Exclude<DashRing, 'unknown'>[]
    description: string
    group?: string
  }): IFeature {
    const defValue = env.ring === 'unknown' ? false : params.rings.includes(env.ring)

    return createFeature({
      key: params.key,
      defaultValue: defValue,
      description: params.description,
      group: params.group,
    })
  }
}
