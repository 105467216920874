import { Column, Right, Row, Spacer } from '@copilot-dash/components'
import { DefaultOptionsArray, SearchTextPrefixType, TeamViewSubMenuIds, UserpOptionsArray } from '@copilot-dash/domain'
import { ISearchTicketOptions } from '@copilot-dash/store'
import { isNil } from 'lodash'
import { FC, memo, useCallback, useState } from 'react'
import { SMALL_SCREEN_WIDTH, useStyles } from './QuickSearch.styles'
import { TeamDateTimeRender } from './TeamDateTimeRender'
import { ColumnSettingButton } from '../../../components/Button/ColumnSettingButton'
import { TicketsFilterButton } from '../../../components/Button/TicketsFilterButton'
import { EmailFilter } from '../../../components/SearchOptions/EmailFilter'
import { SearchTextFilter } from '../../../components/SearchOptions/SearchTextFilter'
import { TenantFilter } from '../../../components/SearchOptions/TenantFilter'
import { SharedQueryName } from '../../../components/SharedQueries/SharedQueryName'
import { SUPPORTED_TAGS } from '../../../components/TicketsFilterPanel/config/SupportedTags'
import { ResizeDimensions, useResizeObserver } from '../../../hooks/useResizeObserver'
import { TeamRoute } from '../../../router'
import { useTeamViewStore } from '../store'

export const QuickSearch: FC = memo(() => {
  const styles = useStyles()
  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const currentArgs = useTeamViewStore((state) => state.route.args)

  const handleChange = useCallback(
    (kv: Partial<ISearchTicketOptions>) => {
      // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      TeamRoute.navigator.navigate({
        ...currentArgs,
        ...kv,
      })
    },
    [currentArgs],
  )

  const handleChangeSearchTextAndPrefix = useCallback(
    (searchTextPrefix: SearchTextPrefixType, searchText?: string) => {
      // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      if (
        searchText !== currentArgs.searchText ||
        (searchTextPrefix !== currentArgs.searchTextPrefix && !isNil(searchText))
      ) {
        TeamRoute.navigator.navigate({
          ...currentArgs,
          searchTextPrefix: searchTextPrefix,
          searchText: searchText,
        })
      }
    },
    [currentArgs],
  )
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      isSmallScreen === false && setIsSmallScreen(true)
    } else {
      isSmallScreen === true && setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimensions.Width, handleResize)
  const filterPanelOpened = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const columnSettingOpened = useTeamViewStore((state) => state.computed.columnSettingOpen)

  const validTags = SUPPORTED_TAGS.filter((tag) => {
    const value = form[tag.key]
    if (isNil(value)) return false
    if ((Array.isArray(value) || typeof value === 'string') && value.length === 0) return false
    return true
  })

  return (
    <Column>
      {form.queryId && <SharedQueryName queryId={form.queryId} />}
      <Row className={styles.wrapper} wrap={isSmallScreen} ref={ref}>
        <SearchTextFilter
          className={styles.utteranceAndResponseFilter}
          searchText={form.searchText}
          searchTextPrefix={form.searchTextPrefix}
          onSubmitSearchTextAndPrefix={handleChangeSearchTextAndPrefix}
          placeholder="Search"
          optionsArray={form.product === 'USERP' ? UserpOptionsArray : DefaultOptionsArray}
        />
        <Spacer width={6} height={32} />
        <EmailFilter
          userId={form.userId}
          onChangeUser={(userId) => handleChange({ userId })}
          onDeselect={() => {
            handleChange({ userId: [] })
          }}
          disableEmailFilter={form.product === 'TenantAdminFeedback'}
          className={styles.emailFilter}
        />
        <Spacer width={6} height={32} />
        <TenantFilter
          onChangeTenant={(tenantIds) => handleChange({ tenantIds })}
          value={form.tenantIds}
          className={styles.tenantIds}
        />
        <Spacer height={32} style={{ flexGrow: 1 }} width={6} />
        <Right className={styles.right}>
          {currentArgs.subMenu !== TeamViewSubMenuIds.TopIssues && <TeamDateTimeRender />}
          <Spacer width={6} />
          <TicketsFilterButton
            placeholder={isSmallScreen ? '' : 'Filters'}
            isTicketsFilterPanelOpen={filterPanelOpened}
            onClickButton={() => {
              useTeamViewStore.getState().toggleFilterPanel((bool) => !bool)
            }}
            badgeCount={validTags.length}
          />
          <Spacer size={4} />
          <ColumnSettingButton
            placeholder={isSmallScreen ? '' : 'Columns'}
            isColumnSettingPanelOpen={columnSettingOpened}
            onClickButton={() => {
              useTeamViewStore.getState().toggleColumnSetting((bool) => !bool)
            }}
          />
        </Right>
      </Row>
    </Column>
  )
})

QuickSearch.displayName = 'QuickSearch'
