import { ISearchTicketOptions } from '@copilot-dash/store'

export const enum CategoryTypeIds {
  'All' = 'All',
  'UnRootCaused' = 'UnRootCaused',
  'TopIssue' = 'TopIssue',
}

export enum RootCauseTab {
  LinkedDSATs = 'Linked DSATs',
  PendingConfirmedDSATs = 'Pending Confirmed DSATs',
}

export const DefaultRootCauseTab = [RootCauseTab.LinkedDSATs, RootCauseTab.PendingConfirmedDSATs]

export const LinkedDSATsTab = [RootCauseTab.LinkedDSATs]

export const NoRecommendationID = '-1'

export const EMPTY_TEAM_ID = '-1'

// Function to create a stable string representation of the object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stableStringify = (obj: any): string => {
  const allKeys: string[] = []
  JSON.stringify(obj, (key, value) => {
    allKeys.push(key)
    return value
  })
  allKeys.sort()
  return JSON.stringify(obj, allKeys)
}

export const defaultFilterForm: ISearchTicketOptions = {
  isAIF: false,
  count: 50,
  offset: 0,
}

export const defaultArgsForAll: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  ticketStatus: CategoryTypeIds.All,
  ...defaultFilterForm,
}

export const defaultLinkedTicketsArgsByRootCause: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  issueId: '',
  ...defaultFilterForm,
}

export const defaultRecommendTicketsArgsByRootCause: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  ticketStatus: CategoryTypeIds.UnRootCaused,
  ...defaultFilterForm,
}

export const defaultArgsForUnRootCause: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  ticketStatus: CategoryTypeIds.UnRootCaused,
  ...defaultFilterForm,
}

export const defaultArgsForTopIssues: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  clusteringIssueId: '',
  batchId: '',
  ...defaultFilterForm,
}

export const defaultArgsForRCR: ISearchTicketOptions = {
  teamId: EMPTY_TEAM_ID,
  count: 1,
  offset: 0,
}

export const KEY_LEFT_DRAWER_OPEN = 'left_drawer_open'
