import { Column, Spacer } from '@copilot-dash/components'
import { ProductIds, TeamId } from '@copilot-dash/domain'
import { InfoLabel } from '@fluentui/react-components'
import { EmailTagPickerFilter } from '../../../Filter/EmailTagPickerFilter'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string | undefined
  onChangeValue: (value: string | undefined) => void
  productId?: ProductIds
  teamId?: TeamId
}

export const AssignedToFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, productId, teamId }) => {
  return (
    <Column>
      <InfoLabel weight="semibold">{getTagDisplayName('dSATAssignedTo')}:</InfoLabel>
      <Spacer size={4} />
      <EmailTagPickerFilter
        emailAddress={selectedValues}
        onChangeUser={onChangeValue}
        showInput={true}
        productId={productId}
        teamId={teamId}
      />
    </Column>
  )
}
