import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { SearchTicketAction } from './SearchTicketAction'
import { ISearchTicketOptions, SearchFacet } from './SearchTicketAction.types'
import { IDashStoreContext } from '../../IDashStoreContext'

export function searchTickets(
  context: IDashStoreContext,
  options: ISearchTicketOptions,
  facets?: Array<SearchFacet>,
): Promise<NewSearchCopilotTicketsResult> {
  return new SearchTicketAction(context).search(options, facets)
}
