import { WorkItemStatus } from '@copilot-dash/domain'
import { IFilterOption } from '../../Filter/IFilterOption.types'

export const OptionSetDSATStatus: IFilterOption[] = [
  {
    key: WorkItemStatus.Untriaged,
    text: 'Untriaged',
  },
  {
    key: WorkItemStatus.TeamAssigned,
    text: 'Team Assigned',
  },
  {
    key: WorkItemStatus.RootCaused,
    text: 'Root Caused',
  },
  {
    key: WorkItemStatus.ClosedFixed,
    text: 'Closed - Fixed',
  },
  {
    key: WorkItemStatus.ClosedByDesign,
    text: 'Closed - By Design',
  },
  {
    key: WorkItemStatus.ClosedNotActionable,
    text: 'Closed - Not Actionable',
  },
]
