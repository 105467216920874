import { WorkItemStatus } from '@copilot-dash/domain'
import { z } from 'zod'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetDSATStatus } from '../../config/OptionSetDSATStatus'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: WorkItemStatus[] | undefined
  onChangeValue: (value: WorkItemStatus[]) => void
  disabled?: boolean
}

export const DSATStatusFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, disabled }) => {
  const optionsList = OptionSetDSATStatus

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-dSATStatusFilter'}
      filterType={getTagDisplayName('dSATStatus')}
      isGroup={false}
      disabled={disabled}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const state = z.array(z.nativeEnum(WorkItemStatus)).safeParse(item)
        if (state.success) {
          onChangeValue(state.data)
        }
      }}
      infoLabelContent={
        <>
          State applies only to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
